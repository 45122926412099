
import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import adService from './service/adService';
import VizSensor from 'react-visibility-sensor';
import abecPages from '../services/abecPages';
import styles from './styles/advertise.module.css';
import clipad from '../../assets/clipad.jpg'
import axios from 'axios';
import authservice from '../Authentication/service/authservice';
import { BANNER_AD_TIME } from '../services/ABeCProperties'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ClipAds extends Component {

    constructor(props) {
        super(props);
        this.currentAd = {};
        this.nextAd = {};
        this.seconds = 0;
        this.bannerTimer = null;
        this._isMounted = false;
        this.state = {
            ad: {
                adLink: '',
            },
            isComponentVisible: false,
            viewed: {},
            loadAd: false
        };
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    componentWillUnmount() {
        this.bannerTimer && clearInterval(this.bannerTimer);
        this.updateVisibleAds(false)
        this._isMounted = false
        //this.seconds = 0;
    }

    componentDidMount() {
        this._isMounted = true;
        this.clearClip()
        console.log(this.props.content_page)
        if (this.props.content_page === "ClipsPage" && this.props.search_data)
            this.getAds()
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.content_page !== prevProps.content_page)
            window.scrollTo(0, 0);

        if (this.props.content_page !== prevProps.content_page) {
            this.getAds()
        }

        const visiblityPropChanged = this.state.isComponentVisible !== prevState.isComponentVisible || this.props.isPageVisible !== prevProps.isPageVisible || this.props.isPopupOpen !== prevProps.isPopupOpen
        if (this.state.ad.id && visiblityPropChanged) {
            if (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen)
                this.startTimer()
            else
                this.stopTimer()
        }
    }

    getListingAds = () => {
        this.setState({
            ad: {
                adLink: ''
            }
        }, () => {
            adService.getAdsByListing(this.props.search_data, "clipad").then(response => {
                if (this.props.viewedAds.length < 138) {
                    this.showAdvertisement(response)
                    //this.updateAdDisplay(this.state.ad.id)
                }
            })
        })
    }

    getAdsBySearch = () => {
        // fetch ads based on search query entered by user
        adService.getAdsBySearch(this.props.search_data, 'clipad').then(response => {
            if (this.props.viewedAds.length < 138) {
                this.showAdvertisement(response)
            }
        })
    }

    getAds = () => {
        this.setState({
            ad: {
                adLink: clipad
            }
        })
        adService.getAdvrtsByPageTypeAndLocation(this.props.current_coords, this.props.content_page, 'clipad')
            .then(response => {
                if (this.state.isComponentVisible && this.props.isPageVisible && this.isEmpty(this.currentAd))
                    if (this.props.viewedAds.length < 138) {
                        this._isMounted && this.showAdvertisement(response)
                    }
            })
    }

    showAdvertisement = (ads) => {
        const storedAd = JSON.parse(sessionStorage.getItem('clipad'))

        if (storedAd && storedAd.page === this.props.content_page) {
            this.currentAd = storedAd
            this.setState({ ad: storedAd, viewed: { [storedAd.id]: this.props.viewedAds.includes(storedAd.id + ":clipad") } })
            this.seconds = 30;
        } else if (!this.isEmpty(ads[0]) || ads[0]) {
            this.nextAd = ads.splice(0, 1)[0];
            if (this.props.viewedAds.includes(this.nextAd.id + ":clipad"))
                this.showAdvertisement(ads) // if next ad is already viewed (works for user own ad)
            else if (this.state.ad.id && this.seconds < BANNER_AD_TIME) {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("clipad", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
                if (this.state.ad.id === this.nextAd.id)
                    this.showAdvertisement(ads)
                else
                    this.nextAd.adLink = process.env.REACT_APP_IMAGES_URL + this.nextAd.advertisement;
            } else {
                this.currentAd = { ...this.nextAd };
                this.currentAd.adLink = process.env.REACT_APP_IMAGES_URL + this.currentAd.advertisement;
                this.setState({ ad: this.currentAd })
                this.currentAd.page = this.props.content_page
                sessionStorage.setItem("clipad", JSON.stringify(this.currentAd))
                this.seconds = 0;
                this.nextAd = {};
            }
            (this.state.isComponentVisible && this.props.isPageVisible && !this.props.isPopupOpen) && this.startTimer()
        } else if (!this.state.ad.id || this.seconds >= BANNER_AD_TIME) {
            this.setState({
                ad: {
                    adLink: clipad
                }
            })
            sessionStorage.removeItem('clipad')
        } else {
            this.nextAd = null;
        }
    }

    startTimer = () => {
        if (this.bannerTimer)
            this.stopTimer();
        if (this.currentAd.id && this.seconds <= BANNER_AD_TIME) {
            if (this.nextAd === null) {
                this.currentAd = {}
                this.setState({ ad: { adLink: clipad } })
            }
            else this.bannerTimer = setInterval(() => {
                this.seconds += 1;
                if (this.seconds > BANNER_AD_TIME) {
                    this.updateViews(this.state.ad.id);
                    this.stopTimer();
                    // if (this.nextAd.id) {
                    //     this.currentAd = { ...this.nextAd };
                    //     this.setState({ ad: this.currentAd })
                    //     this.currentAd.page = this.props.content_page
                    //     sessionStorage.setItem("clipad", JSON.stringify(this.currentAd))
                    //     this.nextAd = {};
                    //     this.seconds = 0;
                    //     this.startTimer();
                    // }
                }
            }, 1000);
        }
    }

    stopTimer = () => {
        clearInterval(this.bannerTimer);
    }

    updateVisibleAds = (isComponentVisible) => {
        const adName = "clipad"
        if (isComponentVisible && !this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds([...this.props.visibleAds, adName])
        } else if (!isComponentVisible && this.props.visibleAds.includes(adName)) {
            this.props.setVisibleAds(this.props.visibleAds.filter(e => e !== adName))
        }
    }

    updateViews = (id) => {
        let listingData = {
            viewedOnListing: null,
            viewed: null,
            section: null,
        }
        if (this.props.search_data.listingId) {
            listingData = {
                viewedOnListing: parseInt(this.props.search_data.listingId),
                viewed: this.props.search_data.isViewed,
                section: this.props.search_data.place,
            }
        }
        adService.updateViews(id, 'clipad', listingData).then(result => {
            this.setState({ viewed: { [id]: result } })
            this.props.sendViewedAds(id + ":clipad");
        })

    }

    clearClip = () => {
        axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/revenue/eligible-to-show-ad?${this.props.is_login ? 'userId=' + authservice.getCurrentUserid() : 'guestId=' + authservice.getCookie('hp.rocks.soon')}`).then(response => {
            if (response.data === true)
                this.setState({ loadAd: true })
        })
    }

    render() {
        const page = abecPages.getPageName(this.props.match.path)
        return (
            <VizSensor
                onChange={(isVisible) => {
                    if (this.state.isComponentVisible !== isVisible) {
                        this.setState({ isComponentVisible: isVisible })
                        this.updateVisibleAds(isVisible)
                    }
                }}
                partialVisibility
                offsetTop={{ top: 200 }}
            >
                <div className={this.props.isMobile ? `${styles.video_container_ad_mob}` : `${styles.clipAd_container}`}>
                    <div className={styles.ad_position_clip}>
                        <strong className="ad_mark">AD</strong>
                    </div>
                    {(!this.isEmpty(this.state.ad) && this.state.ad.mediaType === 'video')
                        ?
                        <video className={`img-fluid clip_video ${this.state.ad.website ? 'cursor_pointer' : ''}`}
                            onClick={() => adService.redirect(this.state.ad.website)}
                            autoPlay={true} loop src={this.state.ad.adLink} type="video/mp4" onLoad={this.handleImageLoad} style={this.props.isMobile ? {width: "100%", height: "auto"} : null}>
                        </video>
                        :
                        <img className={`img-fluid clip_video ${this.state.ad.website ? 'cursor_pointer' : ''}`} id={"clip-ad" + this.props.spot} loading='lazy'
                            onClick={() => adService.redirect(this.state.ad.website)}
                            src={this.state.ad.adLink} alt="Clip Ad" style={this.props.isMobile ? {width: "100%", height: "auto"} : null} />
                    }

                    <div className={styles.ad_position_clip}>
                        {(this.state.viewed[this.state.ad.id]) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                    </div>
                </div>
            </VizSensor>
        )
    }
}

const mapStateToProps = state => {
    return {
        isPopupOpen: state.isPopupOpen,
        current_coords: state.current_coords,
        search_data: state.search_data,
        isPageVisible: state.isPageVisible,
        viewedAds: state.viewedAds,
        visibleAds: state.visibleAds,
        is_login: state.is_login,
        content_page: state.content_page,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        isLogin: (is_login) => dispatch({ type: 'IS_LOGIN', is_login: is_login }),
        sendViewedAds: (viewedAds) => dispatch({ type: 'VIEWED_ADS', viewedAds: viewedAds }),
        setVisibleAds: (visibleAds) => dispatch({ type: 'VISIBLE_ADS', visibleAds: visibleAds }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ClipAds));
