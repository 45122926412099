import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import invites from '../../assets/invites.jpg'

export const ViewsWork = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <div className="col-md-12 col-12">
                        <h1 className={`${FooterStyle.pageHeading} text-center pb-5`}>How to Set Up Your Online Income<br></br> with <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span>?</h1>
                        <div className='col-md-12 col-12 text-center pb-5'>
                            <video className={`${FooterStyle.advt_video_edit}`} height="auto" controls>
                                <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/How-to-Earn.mp4"} type="video/mp4" />
                            </video>
                        </div>
                        <ul>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}>aajubaaju.in is a subscription-based SaaS platform designed to help subscribers generate income from the growing digital economy. Subscribers, known as AajuBaajuians, can monetize their skills or promote their businesses, and even earn by helping others establish their own digital income streams.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>Types of Income on <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h2>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Self Income:</h2>
                                <p className={`${FooterStyle.paragraph}`}><span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span> provides a plug-and-play platform that allows its subscribers to generate income by leveraging their own talents and/or promoting their business.</p>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Digital Entrepreneur Income:</h2>
                                <p className={`${FooterStyle.paragraph}`}>aajubaaju.in has designed a Loyalty Income Program to help your friends and family establish their own digital income sources while you embark on your journey as a digital entrepreneur.This loyalty program not only empowers your network to create sustainable digital income sources but also positions you as a leader in the digital entrepreneurship space.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>How to Setup a Self Income?</h2>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Content Earnings:</h2>
                                <p className={`${FooterStyle.paragraph}`}>AajuBaajuians can post content in 9 segments as per their talent and skill set.</p>
                                <h4 className={`${FooterStyle.paraHeading}`}>The 9 segments are:</h4>
                                <div className='row p-3 text-center'>
                                    <div className='col-md-2'></div>
                                    <table className="bordered-table p-12 col-md-8 col-12">
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Videos</td>
                                            <td>Post long format videos in horizontal orientation.
                                                Minimum 2 minutes duration.
                                            </td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Clips</td>
                                            <td>Post short format videos in vertical orientation.
                                                Maximum 2 minutes duration.
                                            </td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Photos</td>
                                            <td>Post informational photos and product catalogues.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Album</td>
                                            <td>Post personal photos.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Pages</td>
                                            <td>Post articles written in English.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>AajuBaaju My Reviews</td>
                                            <td>Post reviews written in English.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>Business Listings</td>
                                            <td>List businesses.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>Event Listings</td>
                                            <td>List events.</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>Job Listings</td>
                                            <td>List job vacancies.</td>
                                        </tr>
                                    </table>
                                    <div className='col-md-2'></div>
                                </div>
                                <h4 className={`${FooterStyle.paraHeading}`}>Scope of earning by posting content:</h4>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuian who posts content gets a revenue share of 75 paise for each advertisement displayed in the content posted by them when Non-AajuBaajuians watch the advertisements displayed in their content for 30 seconds.</p>
                                <h4 className={`${FooterStyle.paraHeading}`}>Content Earnings Capping:</h4>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuian gets a revenue share @0.75p per advertisement for the 1st 200 advertisements viewed in the content from every 5000 advertisement Views per Day. The Ad Views count is the Total No. of advertisement Views in all the content posted.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Ad View Earnings:</h2>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian will get a revenue share for watching advertisements published in various pages of the platform. The AajuBaajuian has to keep the advertisement in focus for 30 seconds, until a green tick mark displays in the bottom right corner of the advertisement, to get a revenue share for watching that advertisement. The earning per advertisement will be based on the stages depending on the number of AajuBaajuians introduced.</p>
                                <h4 className={`${FooterStyle.paraHeading}`}>Below is the earning metrics based on stages:</h4>
                                <div className='row p-3'>
                                    <div className='col-md-2'></div>
                                    <table className='col-md-8'>
                                        <tr className="table-row extra_height">
                                            <th>No. of AajuBaajuians in Level-1</th>
                                            <th>Stage</th>
                                            <th>Earning per Advertisement</th>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>0-1</td>
                                            <td>Beginner</td>
                                            <td>45 paise</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>2-3</td>
                                            <td>Intermediate</td>
                                            <td>60 paise</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>4-10</td>
                                            <td>Master</td>
                                            <td>75 paise</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td>11+</td>
                                            <td>Expert</td>
                                            <td>90 paise</td>
                                        </tr>
                                    </table>
                                    <div className='col-md-2'></div>
                                </div>
                                <h4 className={`${FooterStyle.paraHeading}`}>Promotion to Stages:</h4>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian will get promoted when he/she introduces aajubaaju.in to others and makes them as AajuBaajuians.</p>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuian will get promoted to<br></br>
                                    Intermediate Stage when the AajuBaajuian makes 2 AajuBaajuians.<br></br>
                                    Master Stage when the AajuBaajuian makes 4 AajuBaajuians.<br></br>
                                    Expert Stage when the AajuBaajuian makes 11 AajuBaajuians.</p>
                                <h4 className={`${FooterStyle.paraHeading}`}>Demotion from Stages:</h4>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian will get demoted when an AajuBaajuian made by him/her does not renew or gets blocked or deleted due to inactiveness* or malpractice or for any other reason affecting aajubaaju.in.</p>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuian will get demoted to<br></br>
                                    Beginner Stage if the AajuBaajuian count reduces to 1 AajuBaajuian.<br></br>
                                    Intermediate Stage if the AajuBaajuian count reduces to 3 AajuBaajuians.<br></br>
                                    Master Stage if the AajuBaajuian count reduces to 10 AajuBaajuians.
                                </p>
                                <p className={`${FooterStyle.paragraph}`}><i>*inactiveness</i> –  Every AajuBaajuian must visit aajubaaju.in and watch at least 1 advertisement for 15 days in a month. An AajuBaajuian who has not visited aajubaaju.in for more than 15 days in a month will be considered inactive and the account will be set for deletion.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>How to Setup Digital Entrepreneur Income?<br></br>
                                    (Passive Income)
                                </h2>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>2 Levels Loyalty Earnings:</h2>
                                <img className="img-fluid" src={invites} alt="referrals" />
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>“B” is Level-1 AajuBaajuian for “U”<br></br>
                                    “C” is Level-2 AajuBaajuian for “U”
                                </h2>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuians will accumulate loyalty earnings equivalent to one-third of the revenue generated by AajuBaajuians in their 2-level community.</p>
                                <p className={`${FooterStyle.paragraph}`}>Loyalty earnings represent the share of revenue that an AajuBaajuian receives when someone in either of their 2 levels earns income through their own efforts. By becoming a loyalty earner, an AajuBaajuian transitions into an entrepreneurial role, helping others find income opportunities.</p>
                                <p className={`${FooterStyle.paragraph}`}>For example, if you are an AajuBaajuian and a Level 1 AajuBaajuian earns 0.75p from posting content or watching ads, you will accumulate 0.25p in loyalty earnings, which is one-third of their earnings. Likewise, if a Level 2 member earns 0.75p through similar activities, you will again accumulate 0.25p.</p>
                                <p className={`${FooterStyle.paragraph}`}>Loyalty earnings are part of the entrepreneurial income model established on the aajubaaju.in platform, which is designed to be a self-sustaining business. To withdraw accumulated loyalty earnings, AajuBaajuians contribute to the platform, thus supporting the overall business model.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>SUBSCRIPTION MODELS:</h2>
                                <h4 className={`${FooterStyle.paraHeading}`}>There are 4 subscription models.</h4>
                                <div className='row'>
                                    <table>
                                        <thead>
                                            <tr className="table-row extra_height">
                                                <th className={FooterStyle.annual_head}>Annual</th>
                                                <th className={FooterStyle.half_head}>One-Time Half Year*</th>
                                                <th className={FooterStyle.monthly_head}>Monthly</th>
                                                <th className={FooterStyle.postpaid_head}>Postpaid</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual}>9,000/- (+18% GST)</td>
                                                <td className={FooterStyle.half_dark}>4,500/- (+18% GST)</td>
                                                <td className={FooterStyle.monthly}>1,500/- (+18% GST)</td>
                                                <td className={FooterStyle.postpaid_dark}>Accumulate 10,620/- (incl. 18% GST)</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual_dark}>Validity 1 Year</td>
                                                <td className={FooterStyle.half_year}>Validity 6 Months</td>
                                                <td className={FooterStyle.monthly_dark}>Validity 1 Month</td>
                                                <td className={FooterStyle.postpaid}>Validity 1 Year</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual}>Renewal in Annual</td>
                                                <td className={FooterStyle.half_dark}>Renewal in Annual</td>
                                                <td className={FooterStyle.monthly}>Renewal in Monthly or Annual</td>
                                                <td className={FooterStyle.postpaid_dark}>Renewal in Annual</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual_dark}>All Stages Applicable</td>
                                                <td className={FooterStyle.half_year}>Intermediate Stage Not Applicable</td>
                                                <td className={FooterStyle.monthly_dark}>Intermediate Stage Not Applicable</td>
                                                <td className={FooterStyle.postpaid}>All Stages Applicable After Upgrade</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual}>Eligible to Withdraw Loyalty Earning</td>
                                                <td className={FooterStyle.half_dark}>Eligible to Withdraw Loyalty Earning</td>
                                                <td className={FooterStyle.monthly}>Eligible to Withdraw Loyalty Earning Only After Renewal in Annual</td>
                                                <td className={FooterStyle.postpaid_dark}>Eligible to Withdraw Loyalty Earning Only After Upgrade</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual_dark}>Loyalty Earning For Parent</td>
                                                <td className={FooterStyle.half_year}>Loyalty Earning For Parent</td>
                                                <td className={FooterStyle.monthly_dark}>No Loyalty Earning For Parent</td>
                                                <td className={FooterStyle.postpaid}>No Loyalty Earning For Parent Until Upgrade</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual}>Stage Change For Parent</td>
                                                <td className={FooterStyle.half_dark}>Stage Change For Parent</td>
                                                <td className={FooterStyle.monthly}>Stage Change For Parent</td>
                                                <td className={FooterStyle.postpaid_dark}>No Stage Change For Parent Until Upgrade</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual_dark}>Counted for Type-1 Loyalty Withdrawal</td>
                                                <td className={FooterStyle.half_year}>Counted for Type-1 Loyalty Withdrawal</td>
                                                <td className={FooterStyle.monthly_dark}>Not Counted for Type-1 Loyalty Withdrawal</td>
                                                <td className={FooterStyle.postpaid}>Not Counted for Type-1 Loyalty Withdrawal</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className={`${FooterStyle.paragraph}`}><i>*The renewal will be in Annual subscription.</i></p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>How to Withdraw the Revenue Share?</h2>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Ad View Revenue Share Withdrawal:</h2>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuians can withdraw their Ad View Revenue Share earned by watching advertisements based on their stage as shown below:</p>
                                <div className='row'>
                                    <table>
                                        <thead>
                                            <tr className="table-row extra_height">
                                                <th className={FooterStyle.annual_head}>Beginner</th>
                                                <th className={FooterStyle.annual_head}>Intermediate</th>
                                                <th className={FooterStyle.annual_head}>Master</th>
                                                <th className={FooterStyle.annual_head}>Expert</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.half_dark}>Reach <strong>Rs.12,000/-</strong><br></br>per Withdrawal</td>
                                                <td className={FooterStyle.half_dark}>Reach <strong>Rs.6,000/-</strong><br></br>per Withdrawal</td>
                                                <td className={FooterStyle.half_dark}>Reach <strong>Rs.3,000/-</strong><br></br>per Withdrawal</td>
                                                <td className={FooterStyle.half_dark}>No<br></br>Minimum<br></br>Withdrawal<br></br>Limit</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Content Revenue Share Withdrawal:</h2>
                                <p className={`${FooterStyle.paragraph}`}>The AajuBaajuians can withdraw their Content Revenue Share earned from the advertisements displayed in their content based on their stage as shown below:</p>
                                <div className='row'>
                                    <table>
                                        <thead>
                                            <tr className="table-row extra_height">
                                                <th className={FooterStyle.half_head}>Beginner</th>
                                                <th className={FooterStyle.half_head}>Intermediate</th>
                                                <th className={FooterStyle.half_head}>Master</th>
                                                <th className={FooterStyle.half_head}>Expert</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.annual_dark}>Reach <strong>Rs.3,000/-<br></br>(or) Rs.5,000/-<br></br>(or) Rs.10,000/-</strong> <br></br>to Withdraw</td>
                                                <td className={FooterStyle.annual_dark}>Reach <strong>Rs.3,000/-<br></br>(or) Rs.5,000/-<br></br>(or) Rs.10,000/-</strong> <br></br>to Withdraw</td>
                                                <td className={FooterStyle.annual_dark}>Reach <strong>Rs.3,000/-<br></br>(or) Rs.5,000/-<br></br>(or) Rs.10,000/-</strong> <br></br>to Withdraw</td>
                                                <td className={FooterStyle.annual_dark}>No <br></br>Minimum<br></br>Withdrawal<br></br>Limit</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Loyalty Revenue Share Withdrawal:</h2>
                                <p className={`${FooterStyle.paragraph}`}>The accumulated Loyalty Earnings can be withdrawn by meeting the following conditions:</p>
                                <div className='row'>
                                    <table>
                                        <thead>
                                            <tr className="table-row extra_height">
                                                <th className={FooterStyle.monthly_head}>Withdrawal Amount</th>
                                                <th className={FooterStyle.monthly_head}>Type - 1*</th>
                                                <th className={FooterStyle.monthly_head}>Type - 2</th>
                                                <th className={FooterStyle.monthly_head}>Type - 3</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.monthly}>Rs.25,000/-</td>
                                                <td className={FooterStyle.monthly}>10 Intermediate AajuBaajuians</td>
                                                <td className={FooterStyle.monthly}>2 Paid Listings<br></br>2 Advertisements</td>
                                                <td className={FooterStyle.monthly}>3 Advertisements</td>
                                            </tr>
                                            <tr className="table-row extra_height">
                                                <td className={FooterStyle.monthly}>Rs.50,000/-</td>
                                                <td className={FooterStyle.monthly}>15 Intermediate AajuBaajuians</td>
                                                <td className={FooterStyle.monthly}>2 Paid Listings<br></br>4 Advertisements</td>
                                                <td className={FooterStyle.monthly}>5 Advertisements</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className={`${FooterStyle.paragraph}`}><i>*Only the AajuBaajuians registered in Half-Year or Annual subscription will be considered for Type-1 loyalty earning withdrawal.</i></p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>With <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span>,<br></br>the power of the digital economy is no longer limited to <br></br> the elite—now everyone can participate and thrive.</h2>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

