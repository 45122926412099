import React, { Component } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

class knowledgeBase extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <main className="grid_container terms_of_service">
                    <div className="row">
                        <section className="col-md-12">
                            <h1 className={`${FooterStyle.footer_links_header} text-center`}>KNOWLEDGE BASE</h1><br></br><br></br><br></br>
                            <ul className='text-center'>
                                {/* style={{ textAlign: 'center' }} > */}
                                <li>
                                    <h2 className='text-left'>MOTTO OF aajubaaju.in &#45; A GOOD NEWS FOR VIDEO CONTENT CREATORS AND BLOGGERS.</h2><br></br>
                                    <video className={FooterStyle.advt_video} height="auto" controls>
                                        <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Motto.mp4"} type="video/mp4" />
                                    </video>
                                </li><br></br><br></br><br></br><br></br>
                                <li>
                                    <h2 className='text-left'>aajubaaju.in A ZERO COST PROMOTION TOOL FOR BUSINESSES.</h2><br></br>
                                    <video className={FooterStyle.advt_video} height="auto" controls>
                                        <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Promotion.mp4"} type="video/mp4" />
                                    </video>
                                </li><br></br><br></br><br></br><br></br>
                                <li>
                                    <h2 className='text-left'>Step by Step Guide to Create a Profile Photo.</h2><br></br>
                                    <video className={FooterStyle.advt_video} height="auto" controls>
                                        <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/ProfileBase.mp4"} type="video/mp4" />
                                    </video>
                                </li><br></br><br></br><br></br><br></br>
                                {/* <li>
                                <h2 className='text-left'>HOW TO WATCH ADVERTISEMENTS IN aajubaaju.in?</h2><br></br>
                                <video className={FooterStyle.advt_video} height="auto" controls>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/WatchAds.mp4"} type="video/mp4" />
                                </video>
                            </li><br></br><br></br><br></br><br></br> */}
                                <li>
                                    <h2 className='text-left'>USE aajubaaju.in WISELY.</h2><br></br>
                                    <video className={FooterStyle.advt_video} height="auto" controls>
                                        <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Wisely.mp4"} type="video/mp4" />
                                    </video>
                                </li><br></br>
                                {/* <li>
                                <h2 className='text-left'>LOOKS TOO GOOD TO BE TRUE! IS aajubaaju.in GENUINE?</h2><br></br>
                                <video className={FooterStyle.advt_video} height="auto" controls>
                                    <source src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Genuine.mp4"} type="video/mp4" />
                                </video>
                            </li><br></br> */}
                            </ul>
                        </section>
                    </div>
                </main>
                <div className="listings_footer">
                    <div className={`${styles.large_rect} row`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </>
        )
    }
}
export default knowledgeBase;