import React, { useEffect, useRef } from 'react'
import dateFormator from '../../utility/dateFormator';
import TextDecoration from '../../utility/TextDecoration';
import { Ratings } from '../reviews/Ratings';
import VizSensor from 'react-visibility-sensor';
import listingService from '../service/listingService';
import styles from './card.module.css'
import { useViewport } from '../../utility/useViewport';

export const VCard = (props) => {
    const listing = props.listing;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg'
    const isMobile = useViewport()

    let isViewCounted = false;
    const timeOut = useRef(null);
    /**const runTimer = (infocus) => {
        if (!isViewCounted && infocus)
            timeOut.current = setTimeout(() => countView(), 5000);
        else if (!infocus && timeOut.current)
            clearTimeout(timeOut.current)
    }**/
    const handleClick = () => {
        !isViewCounted && countView();
        props.showContent(listing, props.section)
    }
    const countView = () => {
        timeOut.current && clearTimeout(timeOut.current)
        isViewCounted = true;
        //listingService.updateSingleListingViews(props.section, listing);
    }
    useEffect(() => {
        return () => timeOut.current && clearTimeout(timeOut.current)
    }, [timeOut])

    return (
        <VizSensor
        /**onChange={(isVisible) => {
            runTimer(isVisible)
        }}**/
        >
            <div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xxl-1 low_padding">
                <div className="listing_card" onClick={handleClick}>
                    <div className="listing_thumb_image">
                        <img className="img-fluid" loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} alt={listing.businessName} />
                    </div>
                    <div className="listing_details">
                        <h6 className={isMobile ? "mob_listing_title" : 'listing_title'}>{TextDecoration.ellipsize(listing.businessName, 40)}</h6>
                        <div className="listing_address">
                            <div className="inner_box">
                                {props.type === 'event' ?
                                    <div className={`${styles.listing_marginBottom}`}>
                                        <div>{dateFormator.scheduleDate(listing.scheduleDate)}</div>
                                        <div>{listing.firstScheduleTime}</div>
                                    </div> : null}
                                {props.type === 'job' ?
                                    <>
                                        <p>{TextDecoration.ellipsize(listing.companyName, 40)}</p>
                                        <p>{listing.experience}</p>
                                        <p>{listing.salary}</p>
                                    </> : null}
                                {props.type !== 'job' ? TextDecoration.ellipsize(listing.businessAddress, 110) : null}
                                {props.type === 'news' || props.type === 'reviews' || props.type === 'article' || props.type === 'photos' ?
                                    <div className="listing_info_box_desc_newsArt"> {TextDecoration.ellipsize(listing.specializedIn, 200)}</div> : null}
                            </div>
                        </div>
                        <div className="listing_address">
                            {/* {props.type === 'business' ? */}
                                <Ratings totalRatings={listing.totalRatings} totalReviews={listing.totalReviews} />
                                
                            {(props.isViewed) ? <i className="fas fa-check-circle viewed_mark"></i> : null}
                        </div>
                    </div>
                    {/* {props.type === 'news' || props.type === 'reviews' || props.type === 'photos' || props.type === 'album' ? null : */}
                        <div className="listing_description text-left">
                            {/* {props.type === 'business' ? */}
                                <>
                                    <p className="description_business">{listing.thumbnailDescription ? TextDecoration.ellipsize(listing.thumbnailDescription, 250) : ''}</p>
                                    {listing.reviewDescription ?
                                        <h6 className="customer_header">A Customer Said:</h6> : null}
                                    <p className="review_desc">{TextDecoration.ellipsize(listing.reviewDescription, 140)}</p>
                                </> 
                                {/* <p className="description">{listing.thumbnailDescription ? TextDecoration.ellipsize(listing.thumbnailDescription, 450) : ''}</p> */}
                            
                        </div>
                </div>
            </div>

        </VizSensor>
    )
}