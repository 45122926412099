import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const EventGuidelines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <main className="grid_container terms_of_service">
                <div className="row">
                    <section className="col-md-12">
                        <ol type="I" className={FooterStyle.margin}>
                            <h1 className='mt-5'>
                                {/* style={{ marginTop: '50px' }}> */}
                                Coming Soon!!!</h1>
                        </ol>
                        <div className='row pb-1'>
                            {/* style={{ paddingBottom: '5px' }}> */}
                            <div className='col-md-9'></div>
                            <div className="col-md-3 add_button animated_back_button">
                                <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                    handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard}`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

