import React, { Component } from 'react';
import styles from './cover.module.css';
import PageCategory from './PageCategory';
import ReviewCategory from './ReviewCategory';
import { CategoryTabs } from './CaregoryTabs';
import PhotoCategory from './PhotoCategory';
import AlbumCategory from './AlbumCategory';
import VideoCategory from './VideoCategory';
import ClipCategory from './ClipCategory';
import { VideoCard } from './VideoCard';
import RecentListings from './RecentListings';

class CategoryData extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        const initialTab = match.params.tab;
        this.state = {
            listing_type: initialTab,
        }
        this.tabs = this.tabs.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (prevProps.match.params.tab !== match.params.tab) {
            this.setState({ listing_type: match.params.tab });
        }

    }

    componentDidMount() {
        const { match } = this.props;
        window.scrollTo(0, 0);
        this.setState({ listing_type: match.params.tab });
    }


    tabs = (id) => {
        if (!id || this.state.listing_type === id) return;

        this.setState({ listing_type: id });
        const clipUrlData = "Aajubaaju My Clips"
        const clipUrl = clipUrlData.replace(/[\s]/gm, '-')
        const videoUrlData = "Aajubaaju My Videos"
        const videoUrl = videoUrlData.replace(/[\s]/gm, '-')
        const pageUrlData = "Aajubaaju My Pages"
        const pageUrl = pageUrlData.replace(/[\s]/gm, '-')
        const photoUrlData = "Aajubaaju My Photos"
        const photoUrl = photoUrlData.replace(/[\s]/gm, '-')
        const albumUrlData = "Aajubaaju My Album"
        const albumUrl = albumUrlData.replace(/[\s]/gm, '-')
        const reviewUrlData = "Aajubaaju My Reviews"
        const reviewUrl = reviewUrlData.replace(/[\s]/gm, '-')
        if (id === 'all')
            this.props.history.replace(`/content/All/${this.props.match.params.subcategory}/${id}`);
        if (id === 'clips')
            this.props.history.replace(`/content/${clipUrl}/${this.props.match.params.subcategory}/${id}`);
        if (id === 'videos')
            this.props.history.replace(`/content/${videoUrl}/${this.props.match.params.subcategory}/${id}`);
        if (id === 'photos')
            this.props.history.replace(`/content/${photoUrl}/${this.props.match.params.subcategory}/${id}`);
        if (id === 'album')
            this.props.history.replace(`/content/${albumUrl}/${this.props.match.params.subcategory}/${id}`);
        if (id === 'pages')
            this.props.history.replace(`/content/${pageUrl}/${this.props.match.params.subcategory}/${id}`);
        if (id === 'reviews')
            this.props.history.replace(`/content/${reviewUrl}/${this.props.match.params.subcategory}/${id}`);
    };

    render() {
        return (
            <>
                <CategoryTabs onToggle={this.tabs} listingType={this.state.listing_type} {...this.props} />
                <div className={`container-fluid ${styles.backgroundStyle}`}>

                    <div className="row">
                        <div className="col-12">
                            {this.state.listing_type === 'all' ?
                                <>
                                    <div className="col-12">
                                        <VideoCard {...this.props} listingType={'article'} setContentStatus={this.setContentStatus} />
                                    </div>
                                    <div className="col-12">
                                        <VideoCard {...this.props} listingType={'clips'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'photos'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'album'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'news'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'reviews'} />
                                    </div>
                                </>
                                : null}
                            <div className="col-12">
                                {this.state.listing_type === 'videos' ?
                                    <VideoCategory {...this.props} listingType={'article'} />
                                    : null}
                                {this.state.listing_type === 'clips' ?
                                    <ClipCategory {...this.props} listingType={'clips'} />
                                    : null}
                                {this.state.listing_type === 'pages' ?
                                    <PageCategory {...this.props} listingType={'news'} />
                                    : null}
                                {this.state.listing_type === 'photos' ?
                                    <PhotoCategory {...this.props} listingType={'photos'} />
                                    : null}
                                {this.state.listing_type === 'album' ?
                                    <AlbumCategory {...this.props} listingType={'album'} />
                                    : null}
                                {this.state.listing_type === 'reviews' ?
                                    <ReviewCategory {...this.props} listingType={'reviews'} />
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default (CategoryData);
