import React from 'react'
import styles from './headings.module.css'

export const TitleHeading = (props) => {
    return (
        <h2 className={`${styles.title_heading} ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}>
            {props.text ? props.text : props.children}
        </h2>
    )
}