import React from 'react'
import { VideoVCard } from './VideoVCard'

export const VideoCardsRow = (props) => {
    return (
        <div className="row pt-4 pb-5">
            {props.listings.map(listing => {
                return <VideoVCard
                    {...props}
                    key={listing.hpId}
                    listing={listing}
                    isViewed={props.viewedContentIds.includes(listing.hpId)}
                />
            })}
        </div>
    )
}