import React, { useEffect, useState, useRef } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux'
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

export const ClipContent = (props) => {

  const [listing] = useState(props.listing);
  const [editorState, setEditorState] = useState(null);
  const isAdmin = useSelector(state => state.isAdmin)
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState({});

  const handlePlayPause = () => {
    const video = document.getElementById('myVideo');
      if (video.paused) {
        console.log("Playing video");
        video.play();
        setIsPlaying(true);
      } else {
        console.log("Pausing video");
        video.pause();
        setIsPlaying(false);
      }
  };

  useEffect(() => {
    if (listing.listingType === 'clips') {
      if (listing.myvideos_content) {
        const con = JSON.parse(listing.myvideos_content)
        const contentState = convertFromRaw(con);
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  }, [listing]);


  if (!props.listing.hpId)
    return <> </>
  else {
    return (
        <div className='billBoard'>
          <div className='col-md-12 text-center'>
            {props.listing.elements.map((element, index) => (
              <div key={element.value + index} >
                {element.type === 'video' ?
                  <div className="video-container">
                    <video
                      className='clip_video'
                      ref={videoRef}
                      src={process.env.REACT_APP_IMAGES_URL + element.value}
                      loop
                      id="myVideo"
                      autoPlay
                    ></video>
                    <button onClick={handlePlayPause} className="play-pause-button">
                      <i className={`fa ${isPlaying ? 'fa-pause' : 'fa-play'} styled-icon`}></i>
                    </button>
                  </div> : null}
              </div>
            ))}
            {isAdmin && listing.myvideos_content !== '' && listing.listingType === 'clips' ?
              <div className="rdw-storybook-root">
                <Editor
                  editorState={editorState}
                  wrapperClassName="rdw-storybook-wrapper"
                  readOnly
                  toolbarHidden
                />
              </div> : null}
          </div>
        </div>
    )
  }
}

export default (ClipContent); 
