import React from 'react'
import styles from './errors.module.css'

export const Errors = (props) => {
    return (
        <span id={props.id} className={`${styles.error} ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}>
            {props.text ? props.text : props.children}
        </span>
    )
}