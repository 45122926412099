import moment from "moment";

class dateFormator {
    MONTH_NAMES = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    localDateFormat = { year: 'numeric', month: 'long', day: 'numeric' };
    localTimeFormat = { hour: '2-digit', hour12: true, minute: '2-digit' }

    getFormattedDate = (date, prefomattedDate = false, hideYear = false) => {
        const day = date.getDate();
        const month = this.MONTH_NAMES[date.getMonth()];
        const year = date.getFullYear();
        const hours = date.getHours();
        let minutes = date.getMinutes();

        if (minutes < 10) {
            // Adding leading zero to minutes
            minutes = `0${minutes}`;
        }

        if (prefomattedDate) {
            // Today at 10:20
            // Yesterday at 10:20
            return `${prefomattedDate} at ${hours}:${minutes}`;
        }

        if (hideYear) {
            // 10. January at 10:20
            return `${day}, ${month} at ${hours}:${minutes}`;
        }

        // 10. January 2017. at 10:20
        return `${day}, ${month} ${year}. at ${hours}:${minutes}`;
    }


    // --- Main function
    timeAgo = (dateParam) => {
        if (!dateParam) {
            return null;
        }

        const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
        const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
        const today = new Date();
        const yesterday = new Date(today - DAY_IN_MS);
        const seconds = Math.round((today - date) / 1000);
        const minutes = Math.round(seconds / 60);
        const isToday = today.toDateString() === date.toDateString();
        const isYesterday = yesterday.toDateString() === date.toDateString();
        const isThisYear = today.getFullYear() === date.getFullYear();


        if (seconds < 5) {
            return 'now';
        } else if (seconds < 60) {
            return `${seconds} seconds ago`;
        } else if (seconds < 90) {
            return 'about a minute ago';
        } else if (minutes < 60) {
            return `${minutes} minutes ago`;
        } else if (isToday) {
            return this.getFormattedDate(date, 'Today'); // Today at 10:20
        } else if (isYesterday) {
            return this.getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
        } else if (isThisYear) {
            return this.getFormattedDate(date, false, true); // 10. January at 10:20
        }

        return this.getFormattedDate(date); // 10. January 2017. at 10:20
    }

    scheduleDate = (dateString) => {
        if (dateString === null)
            return '';
        const dateParts = dateString.split("/");
        // month is 0-based, that's why we need dataParts[1] - 1
        const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        return dateObject.toLocaleDateString('en-IN', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    }

    date = (dateString) => {
        if (!dateString)
            return '';
        return new Date(dateString).toLocaleDateString('en-IN', this.localDateFormat);
    }

    dateTime = (dateString) => {
        if (!dateString)
            return '';
        const date = new Date(dateString);
        return `${date.toLocaleDateString('en-IN', this.localDateFormat)} ${date.toLocaleTimeString('en-IN', this.localTimeFormat)}`;
    }

    simpleDateTimeFormat = (timeStamp) => {
        if (!timeStamp)
            return '';
        return moment(timeStamp).format('DD/MM/YYYY HH:mm');
    }

    simpleDateFormat = (timeStamp) => {
        if (!timeStamp)
            return '';
        return moment(timeStamp).format('DD/MM/YYYY');
    }

    addDate(dt, amount, dateType) {
        switch (dateType) {
            case 'days':
                return dt.setDate(dt.getDate() + amount) && dt;
            case 'weeks':
                return dt.setDate(dt.getDate() + (7 * amount)) && dt;
            case 'months':
                return dt.setMonth(dt.getMonth() + amount) && dt;
            case 'years':
                return dt.setFullYear(dt.getFullYear() + amount) && dt;
        }
    }
}
export default new dateFormator();