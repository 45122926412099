import React from 'react'
import { AnimatedText } from '../../AnimatedText'
import { FormLabels } from '../../UIElements/Form_label/FormLabels'
import { FileInput } from '../../UIElements/input/FileInput'

const MyVideoInput = (props) => {

    const { fileURL = null, file } = props.elements[0];

    return (
        <div id={'video_data'}>
            <div className="row form-row" id={'article_news_data0'}>
                <div className="form-group col-md-2 text-right">
                    <FormLabels text={props.listingType === 'article' ? 'Video *' : 'Clip *'} htmlFor="inputZip" />
                </div>
                <div className="form-group col-md-8">
                    <div className="input-group input-upload">
                        <FileInput
                            file_url={fileURL}
                            disabled={false}
                            fileType='video'
                            fileFormats='video/*'
                            labelText={props.listingType === 'article' ? 'Choose Video' : 'Choose Clip'}
                            onFileSelect={(e) => props.handleVideoSelect(e, 0)}
                        />
                    </div>
                </div>
                <div className="form-group col-md-2">
                    {(!props.inputStatus[0]) ?
                        <div>
                            {(file) &&
                                <button type='button' className="btn btn-success media-btn" onClick={() => props.handleUpload(0, 'video')}>
                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                </button>}
                        </div> :
                        <div> <AnimatedText text={'uploading'} /> </div>}
                </div>
            </div>
        </div>
    )
}

export default MyVideoInput