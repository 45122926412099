import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const SuccessStoryArc = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={`${FooterStyle.pageHeading} text-center`}><span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span> with a Mission <br></br> in Sync with the Vision </h1>
                        <h2 className={`${FooterStyle.sectionHeading} pt-5`}><span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span> who have started to withdraw their loyalty earnings.</h2>

                        <div className="row">
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/reshmaafrin.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/manishpadinesh.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/thilaktillu.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/reshmavazid.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/KanagiriSNPrasad.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/dineshmanishpa.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Vijaya.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/nithygeorge24.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/jellaupendar.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/NAVEENKASALWAR.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Harshaharshith.jpeg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/sai9999.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/swarna9999.jpg"} />
                            </div>
                            <div className="col-6 col-md-3 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/lavanyaj.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/riya_25.jpeg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Vika.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Rsbb.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/manjusingh.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/sujith.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/ramjan.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/gowtham.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/dhananjay.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/manisharathore.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/earnzone.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/samal.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/abhishek.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/veera123.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/jhuma007.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/rohitrudraksha.jpg"} />
                            </div>
                            <div className="col-6 col-md-4 col-lg-2 mb-4">
                                <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/nikrum.jpg"} />
                            </div>

                            {/* <img className='img-fluid' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Kalpana.jpg"} /> */}
                        </div>
                        <h2 className={`${FooterStyle.highlighter} pt-5`}>Here are some of the Success Stories shared by some of the <span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span>.</h2>
                        <ul>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pt-5 pb-5`}>Second Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Second-Time-Loyalty-Earnings-Withdrawal/7559">
                                        <img className="img-fluid" width='650px' height='auto' src="https://files.aajubaaju.in/aajubaaju-58jzzk8gpf-telagra.jpg" alt="Second Loyalty Earning Withdrawal Success Story" />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o  ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/My-First-Loyalty-Experience/7558">
                                        <img className="img-fluid" width='650px' height='auto' src={"https://files.aajubaaju.in/aajubaaju-sq0sir71lr--image-2024-02-02-at-9.41.41-pm.jpeg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/My-Shocking-Aajubaaju-Story--How-It-Changed-My-Life/7431">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/LoyaltyNaveen1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Sharing-My-happiness-And-Experience-About-Aajubaaju-in/7423">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/LoyaltyHarshith1.png"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/First-Time-Loyalty-Earnings-Withdrawal/7408">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/LoyaltyLavnya1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/My-First-Loyalty-Experience/7335">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/ss_loyalty1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Eighth Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Eighth-Loyalty-Income-from-aajubaaju-in-Platform/7183">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/loyalty_8.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Seventh Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Seventh-Loyalty-Income-by-aajubaaju-in-Platform/7147">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/loyalty_7.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Soar-Your-Desire/7065">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Riya_1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/My-First-Loyalty-Experience-In-Aajubaaju/7039">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Manju_1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Sixth Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Sixth-Loyalty-Income-by-AajuBaaju-Platform/6972">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/loyalty_6.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Fifth Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/Fifth-Loyalty-Income-By-AajuBaaju-in-Platform/6681">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/loyalty_5.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Fourth Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/Fourth-Time-Loyalty-Earnings-Withdrawal/6751">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/loyalty_4.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Third Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Third-Time-Loyalty-Earnings-Withdrawal/6536">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/ReshmaLoyalty3.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/First-Loyalty-Earning-Experience-In-Tamil/6534">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/nithyaLoyalty1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/First-Loyalty-Earning-Experience/6514">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/nithyaLoyalty11.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center pt-5'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Third Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/Third-Loyalty-Income-By-AajuBaaju-in-Platform/6408">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty9.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/My-Referral-Withdrawn-Loyalty-Earnings/6109">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty8.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/First-Time-Loyalty-Earnings-Withdrawal/6363">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty7.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Second Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/Second-Loyalty-Earning-By-Best-Online-Platform-AajuBaaju-in/6322">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty10.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/First-Time-Loyalty-Earnings-Withdrawal/6098">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty6.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://www.aajubaaju.in/l/My-First-Loyalty-Earnings-on-Auspicious-Day-of-Vinayka-Chavithi/6137">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty5.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Journey-For-The-First-Loyality-Income-By-AajuBaaju-Platform/5990">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty3.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>Second Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/Second-Time-Loyalty-Earnings-Withdrawal/5874">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty4.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/How-To-Withdraw-Loyalty-Earning/5706">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty2.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li className='text-center'>
                                <h4 className={`${FooterStyle.paraHeading} pb-5`}>First Loyalty Earning Withdrawal Success Story:</h4>
                                <div className="image-container" style={{ display: 'inline-block', position: 'relative' }}>
                                    <a href="https://aajubaaju.in/l/How-to-Withdraw-Loyalty-Earnings/5558">
                                        <img className="img-fluid" width='650px' height='auto' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Loyalty1.jpg"} />
                                        <div className={FooterStyle.overlay}>
                                            <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <div className='row col-md-12'>
                                <div className='col-md-9'></div>
                                <div className="col-md-3 justify-content-right add_button animated_back_button">
                                    <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                        handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}