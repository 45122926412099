import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import Loader from '../utility/Loader';
import authservice from '../Authentication/service/authservice';
import LargeRectangle from '../advertisements/LargeRectangle';
import debounce from 'lodash/debounce';
import ClipContentMob from './ClipContentMob';
import ClipAds from '../advertisements/ClipAds';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

class ClipListingMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clipCache: [],
            currentIndex: 0,
            error: null,
            showAd: false,
            numberOfDownSwap: 1,
            showSwipeText: false,
        };
        this.touchStartX = 0;
        this.handleTouchEndDebounced = debounce(this.handleTouchEnd, 50);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.callClipDetails(this.props.match.params.hpId);
        window.addEventListener('touchstart', this.handleTouchStart);
        window.addEventListener('touchend', this.handleTouchEnd);
        this.timer = setTimeout(() => {
            this.setState({ showSwipeText: true });
        }, 300);
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.handleTouchStart);
        window.removeEventListener('touchend', this.handleTouchEnd);
        clearTimeout(this.timer);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentIndex !== prevState.currentIndex) {
            window.scrollTo(0, 0);
        }
    }

    handleTouchStart = (event) => {
        this.touchStartX = event.touches[0].clientX;
    }

    handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const deltaX = this.touchStartX - touchEndX;
        //this.setState({ showAd: false });
        if (deltaX > 50) {
            if (event.cancelablel)
                event.preventDefault();
            this.setState({ showAd: false });
            this.setState(prevState => ({
                numberOfDownSwap: prevState.numberOfDownSwap + 1
            }), () => this.loadNextClip());
        } else if (deltaX < -50) {
            if (event.cancelablel)
                event.preventDefault();
            this.loadPrevClip()
        }
        this.touchStartX = 0;
    }

    updateURL = () => {
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];
        if (currentClipData && currentClipData.clipListing && currentClipData.clipListing.businessName) {
            const businessName = currentClipData.clipListing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
            this.props.history.push(`/clips/${businessName}/${currentClipData.clipListing.hpId}`);
        }
    }

    callClipDetails = (hpId) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getlistings/${hpId}?${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`;

        axios.get(url)
            .then(response => {
                const clipCache = [];
                clipCache.push({
                    clipListing: response.data,
                    subCategories: response.data.subCategories
                });
                this.setState({ clipCache }, () => this.fetchClipFromServer()); // Call loadNextClip after setting initial clip data
            })
            .catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data });
            });
    }

    fetchClipFromServer = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/get-next-clips?${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`)
            .then(response => {
                const nextClipListing = response.data;
                sessionStorage.removeItem("clipad")
                if (nextClipListing && nextClipListing.subCategories && nextClipListing.subCategories.length > 0) {
                    const nextClipData = {
                        clipListing: nextClipListing,
                        subCategories: nextClipListing.subCategories
                    };
                    this.setState(prevState => ({
                        clipCache: [...prevState.clipCache, nextClipData]
                    }));
                } else {
                    console.log("No next clips available.");
                }
            })
            .catch(error => {
                Loader.stopSpinner();
                console.error('Error fetching next clip:', error);
                if (error.response)
                    this.setState({ error: error.response.data });
            });
    }

    loadPrevClip = () => {
        this.setState(prevState => ({
            currentIndex: Math.max(prevState.currentIndex - 1, 0)
        }), () => this.updateURL());
    }

    loadNextClip = () => {
        sessionStorage.removeItem("clipad")
        if (!this.state.showAd && this.state.currentIndex === this.state.clipCache.length - 1) {
            this.setState({ showAd: true })
            return
        }
        if (!this.state.showAd && this.state.numberOfDownSwap % 6 == 0) {
            this.setState({ showAd: true })
            return;
        }

        if (this.state.currentIndex < this.state.clipCache.length - 1) {
            console.log("loading from cache")
            this.setState(prevState => ({
                currentIndex: prevState.currentIndex + 1
            }), () => {
                this.updateURL()
                if (this.state.currentIndex == this.state.clipCache.length - 1) {
                    this.fetchClipFromServer();
                }
            });
        }
    }

    render() {
        const { clipCache, currentIndex, showSwipeText } = this.state;
        const currentClipData = clipCache[currentIndex];

        return (
            <div className='clipListingMobile'>
                {this.state.showAd
                    ?
                    <>
                        <div style={{ position: 'absolute', top: '18px', left: '18px' }}>
                            <Link to='/' style={{ position: 'absolute' }}><i style={{ fontSize: '45px' }} className="fa fa-home home_link styled-icon"></i></Link>
                        </div>
                        <div className='text-center'>
                            <ClipAds spot={"-1"} />
                        </div>
                    </>
                    : currentClipData && (
                        <>
                            <ClipContentMob listing={currentClipData.clipListing} />
                            {showSwipeText && (
                                <div className="row" style={{ color: '#ccc', justifyContent: 'space-between', paddingBottom: '10px', transform: 'translateY(-20px)' }}>
                                    <div className="text" style={{ paddingLeft: '25px', fontWeight: '500' }}><i class='fas fa-angle-double-left'></i>Swipe</div>
                                    <div className="text" style={{ paddingRight: '25px', fontWeight: '500' }}>Swipe<i class='fas fa-angle-double-right'></i></div>
                                </div>
                            )}
                        </>

                    )
                }

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_login: state.is_login,
        listing_id: state.listing_id,
        viewedListings: state.viewedListings,
        search_data: state.search_data,
        clipadAds: state.clipadAds,
    };
};

export default connect(mapStateToProps)(ClipListingMobile);
