import React from 'react'
import styles from './package-selector.module.css'

export const PackageSelector = (props) => {
    return (
        <div className={`${props.value} ${styles.box}`}>
            <div className={`${styles.box_header}`}>{props.title}</div>
            <span className={`${styles.box_span}`}>{props.packageCost ? `₹${props.packageCost}/Year` : 'Free'}</span>
            <span className={`${styles.box_span}`}>1 Year Validity</span>
            <span className={`${styles.box_span}`}>{props.noSimilarListing ? 'No Similar Listing Section' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.sponsored ? 'Can Buy Sponsored Section' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.featured ? 'Can Buy Featured Section' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.slider ? 'Slider' : props.banner ? 'Banner' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.multipleAddress ? 'Multiple Address' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.workingHours ? 'Working Hours' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.map ? 'Map' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.socialMediaShare ? 'Social Media Sharing' : 'NA'}</span>
            <span className={`${styles.box_span}`}>{props.reviews ? 'Rating & Comment' : 'NA'}</span>
            {props.selectedListingType.value !== 'job' && props.selectedListingType.value !== 'event' &&
                <>
                    <span className={`${styles.box_span}`}>{props.discussion ? 'Discussion' : 'NA'}</span>
                    <span className={`${styles.box_span}`}>{props.media ? 'Image' : 'NA'}</span>
                    {/* <span className={`${styles.box_span}`}>{props.gallery ? 'Gallery' : 'NA'}</span> */}
                </>}
            <label>
                <input className={`${styles.select_input}`} type="radio" name="package" value={props.value}
                    checked={props.package === props.value} disabled={props.disabled}
                    onChange={props.setPackage} />
                <div className={`${styles.box_footer}`}>Select</div>
            </label>
        </div>
    )
}