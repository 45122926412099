import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import BillBoard from '../../advertisements/BillBoard';
import LargeRectangle from '../../advertisements/LargeRectangle';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import Loader from '../../utility/Loader';
import { useViewportBillBoard } from '../../utility/useViewport';
import listingService from '../service/listingService';
import { VideoCardsRow } from '../cards/VideoCardsRow';

export const VideoListings = (props) => {
    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const heading = {
        business: 'Business Listings',
        event: 'Event Listings',
        job: 'Job Listings',
        news: 'AajuBaaju My Pages',
        reviews: 'AajuBaaju My Reviews',
        article: 'AajuBaaju My Videos',
        clips: 'AajuBaaju My Clips',
        photos: 'AajuBaaju My Photos',
        album: 'AajuBaaju My Album'
    }

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = listing.listingType === 'article' ? encodeURI(`/l/${businessName}/${listing.hpId}`) : encodeURI(`/clips/${businessName}/${listing.hpId}`)  ;
        props.history.push(listingPath, {
            place: null,
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        //Loader.runSpinner()
        listingService.getRecentListingsByType(coords, props.listingType).then(data => {

            setListings(data)
        }).catch(() => {
            Loader.stopSpinner()
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <div className='pt-lg-4'><TitleHeading text={`Recently Added ${heading[props.listingType]}`} /></div>
                <VideoCardsRow listings={listings}
                    showContent={getListingDetails}
                    viewedContentIds={viewedListings}
                    type={props.listingType} />
                <div className='row' style={{backgroundColor:'#e5e5f2', paddingTop: '100px', paddingBottom: '100px'}}>
                    {isBillboard ? <LargeRectangle spot={props.bannerAdSpot} />
                        : <BillBoard spot={props.bannerAdSpot} />}
                </div>
            </div>
            : <></>
    )
}