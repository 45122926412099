class TextDecoration {
    ellipsize = (text, maxLength) => {
        var dots = "...";
        if (text && text.length > maxLength) {
            // you can also use substr instead of substring
            text = text.substring(0, maxLength) + dots;
        }
        return text;
    };

    getReviewCount = (reviewCount) => {
        if (reviewCount === 0)
            return 'No Reviews';
        else if (reviewCount === 1)
            return "1 Review";
        else
            return `${reviewCount} Reviews`;
    }

    maskEmail = (email) => {
        return email.replace(/^(.)(.*)(.@.*)$/,
            (_, a, b, c) => a + b.replace(/./g, '*') + c
        );
    }
}

export default new TextDecoration();