import React, { useEffect, useRef } from 'react'
import TextDecoration from '../utility/TextDecoration';
import styles from './cover.module.css'
import listingService from '../listings/service/listingService';

export const CardsListingData = (props) => {
    const listing = props.listing;
    const thumb = listing.thumbnailImage ? listing.thumbnailImage : 'assets/Desert.jpg'

    let isViewCounted = false;
    const timeOut = useRef(null);
    const handleClick = () => {
        !isViewCounted && countView();
        props.showContent(listing)
    }
    const countView = () => {
        timeOut.current && clearTimeout(timeOut.current)
        isViewCounted = true;
        //listingService.updateSingleListingViews(null, listing);
    }
    useEffect(() => {
        return () => timeOut.current && clearTimeout(timeOut.current)
    }, [timeOut])

    return (
        <div key={listing.hpId} className={`col-lg-6 col-xl-4 ${styles.basic_listing_info}`}>
            <div className={`${styles.listing_info_box_basic_home} row form-row curser_hand mx-0`} onClick={handleClick}>
                <div className="col-4 no_padding">
                    <div className={`${styles.home_thumb_image_basic}`}>
                        <img alt={listing.businessName} className={`img-fluid ${styles.home_thumb_image_basic_img}`} loading='lazy' src={process.env.REACT_APP_IMAGES_URL + thumb} />
                    </div>
                </div>
                <div className={`col-8 ${styles.home_Desc}`}>
                    <h3 className={`${styles.home_link}`} >{TextDecoration.ellipsize(listing.businessName, 70)}</h3>

                    <div className={`${styles.basic_home_listing_info_box}`}>
                        {TextDecoration.ellipsize(listing.specializedIn, 100)}
                    </div>
                    <div className={`${styles.basic_home_listing_info_box_desc}`}>
                        <span className={styles.name_span}>By:</span> {listing.subCategories}
                    </div>
                </div>
                {(props.viewedContentIds.includes(listing.hpId)) ?
                    <div className="col-md-12">
                        <i className={`fas fa-check-circle ${styles.viewed_mark_home} ${styles.viewed_right_home}`}></i>
                    </div> : null
                }
            </div>
        </div>
    )
}