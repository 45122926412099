import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const ReviewsGuidelines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <section className="col-md-12">
                        <ol type="I" className={FooterStyle.margin}>
                            <h4 className={`${FooterStyle.paraHeading} pt-3`}>
                                {/* style={{'margin-top': '40px'}}> */}
                                General Guidelines:</h4>
                            <h4 className={`${FooterStyle.paraHeading} pt-5`}>Niche Level Guidelines for AajuBaaju My Reviews:</h4>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Gadget Reviews:</h4>
                                <ol type="1">
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should upload one photo of the gadget along with the AajuBaajuian and one same product photo.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>The 500 words should exclude the product specifications. Products specifications and reitereation of product specifications will not be considered for word count.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Restaurant Reviews:</h4>
                                <ol type="1">
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should upload one photo of AajuBaajuian in front of the name of the restaurant and one photo of AajuBaajuian having food inside the restaurant.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Address and contact details of the restaurant.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Shop Reviews:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should upload one photo of AajuBaajuian in front of the name of the shop and one photo of AajuBaajuian inside the shop with the owner or authorized person.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Address and contact details of the shop.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Location Reviews:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should upload one photo of AajuBaajuian from the location.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should review about specific details and facilities which would help others in visiting the location.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>IMPORTANT NOTE:</h4>
                                <p className={`${FooterStyle.paragraph}`}>We will be adding/updating the guidelines as and when we come across a need, based on the user behaviour. It is every AajuBaajuian's reponsibility to check the niche guidelines every time before they create a write a review content.</p>
                            </li>
                        </ol>
                        <div className='row pb-1'>
                            {/* style={{paddingBottom:'5px'}}> */}
                            <div className='col-md-9'></div>
                            <div className="col-md-3 add_button animated_back_button">
                                <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                    handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

