import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import Loader from '../utility/Loader';
import authservice from '../Authentication/service/authservice';
import debounce from 'lodash/debounce';
import ClipAd from '../advertisements/ClipAd';
import ClipContentMob from './ClipContentMob';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ClipAds from '../advertisements/ClipAds';

class CLipShareListingMobile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clipCache: [],
            subCategories: [],
            currentIndex: 0,
            error: null,
            showAd: false,
            numberOfDownSwap: 1,
            isNextButtonDisabled: false,
        };
        this.touchStartX = 0;
        this.handleTouchEndDebounced = debounce(this.handleTouchEnd, 200);
        this.viewTimer = null;
    }

    componentDidMount() {
        this.clearClip()
        window.scrollTo(0, 0);
        this.callClipDetails(this.props.match.params.hpId);
        window.addEventListener('touchstart', this.handleTouchStart);
        window.addEventListener('touchend', this.handleTouchEnd);
    }

    componentWillUnmount() {
        window.removeEventListener('touchstart', this.handleTouchStart);
        window.removeEventListener('touchend', this.handleTouchEnd);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentIndex !== prevState.currentIndex) {
            window.scrollTo(0, 0);
        }
    }

    clearClip = () => {
        if (this.props.is_login) {
            axios.post(process.env.REACT_APP_BACKEND_URL + `/user-activity/clear-trash-memory?${'userId=' + authservice.getCurrentUserid()}`).then(response => {
                this.setState({ clipListing: response.data });
            }).catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data })
            })
        }
        else {
            axios.post(process.env.REACT_APP_BACKEND_URL + `/guest-activity/clear-trash-memory?${'guestId=' + authservice.getCookie('hp.rocks.soon')}`).then(response => {
                this.setState({ clipListing: response.data });
            }).catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data })
            })
        }
    }

    // handleDisableBtn = () => {
    //     axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/revenue/eligible-to-show-ad?${this.props.is_login ? 'userId=' + authservice.getCurrentUserid() : 'guestId=' + authservice.getCookie('hp.rocks.soon')}`).then(response => {
    //         if (response.data === true) {
    //             this.setState({ isNextButtonDisabled: true })
    //             clearTimeout(this.viewTimer);
    //             this.viewTimer = setInterval(() => {
    //                 if (this.state.clipCache.length < 1)
    //                     this.setState({ isNextButtonDisabled: false })
    //             }, 32000);
    //         } else this.setState({ isNextButtonDisabled: false })
    //     })
    // }

    handleDisableBtn = () => {
        const { clipCache, currentIndex } = this.state;
        const totalClips = clipCache.length;
    
        this.setState({ isNextButtonDisabled: true });
    
        clearTimeout(this.viewTimer);
    
        // After 30 seconds, re-enable the button if more clips are available
        this.viewTimer = setTimeout(() => {
            if (currentIndex < totalClips - 1) {
                // Enable button if there are more clips
                this.setState({ isNextButtonDisabled: false });
            } else {
                // Keep button disabled if no more clips are available
                this.setState({ isNextButtonDisabled: true });
            }
        }, 30000); // 30 seconds
    };

    handleTouchStart = (event) => {
        this.touchStartX = event.touches[0].clientX;
    }

    handleTouchEnd = (event) => {
        const touchEndX = event.changedTouches[0].clientX;
        const deltaX = this.touchStartX - touchEndX;
        //this.setState({ showAd: false });
        if (deltaX > 50) {
            if (event.cancelablel)
                event.preventDefault();
            if (this.state.isNextButtonDisabled === true)
                console.log('Wait for 30 seconds')
            else {
                this.setState({ isNextButtonDisabled: false });
                this.setState({ showAd: false });
                this.setState(prevState => ({
                    numberOfDownSwap: prevState.numberOfDownSwap + 1
                }), () => this.loadNextClip());
            }
        } else if (deltaX < -50) {
            if (event.cancelablel)
                event.preventDefault();
            if (this.state.isNextButtonDisabled === true)
                console.log('Wait for 30 seconds')
            else {
                this.setState({ isNextButtonDisabled: false })
                this.loadPrevClip()
            }
        }
        this.touchStarX = 0;
    }

    updateURL = () => {
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];
        if (currentClipData && currentClipData.clipListing && currentClipData.clipListing.businessName) {
            const businessName = currentClipData.clipListing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
            this.props.history.push(`/shareclips/${businessName}/${currentClipData.clipListing.hpId}`);
        }
    }

    callClipDetails = (hpId) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getlistings/${hpId}?${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`;

        axios.get(url)
            .then(response => {
                const clipCache = [];
                const subCategories = []
                clipCache.push({
                    clipListing: response.data,
                    subCategories: response.data.subCategories
                });
                this.setState({ subCategories: response.data.subCategories })
                this.setState({ clipCache, subCategories }, () => this.fetchClipFromServer()); // Call loadNextClip after setting initial clip data
            })
            .catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data });
            });
    }

    fetchClipFromServer = () => {
        console.log("fetching more clips from server")
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];
        {
            currentClipData.subCategories.map((subcategory) => {
                axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/next-clips?${'subCategory=' + subcategory.subCategory}&${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`)
                    .then(response => {
                        const nextClipListing = response.data;
                        localStorage.removeItem("clipad")
                        if (nextClipListing && nextClipListing.subCategories && nextClipListing.subCategories.length > 0) {
                            const nextClipData = {
                                clipListing: nextClipListing,
                                subCategories: nextClipListing.subCategories
                            };
                            this.setState(prevState => ({
                                clipCache: [...prevState.clipCache, nextClipData]
                            }));
                        } else {
                            console.log("No next clips available.");
                        }
                    })
                    .catch(error => {
                        Loader.stopSpinner();
                        console.error('Error fetching next clip:', error);
                        if (error.response)
                            this.setState({ error: error.response.data });
                    });
            })
        }
    }

    loadPrevClip = () => {
        this.setState(prevState => ({
            currentIndex: Math.max(prevState.currentIndex - 1, 0)
        }), () => this.updateURL());
    }

    loadNextClip = () => {
        sessionStorage.removeItem("clipad")
        if (!this.state.showAd && this.state.currentIndex === this.state.clipCache.length - 1) {
            this.handleDisableBtn();
            this.setState({ showAd: true })
            return
        }
        if (!this.state.showAd && this.state.numberOfDownSwap % 6 == 0) {
            sessionStorage.removeItem("clipad");
            this.handleDisableBtn()
            this.setState({ showAd: true })
            //this.props.history.push(`/shareclips/ad/0`);
            return;
        }

        if (this.state.currentIndex < this.state.clipCache.length - 1) {
            console.log("loading from cache")
            this.setState(prevState => ({
                currentIndex: prevState.currentIndex + 1
            }), () => {
                this.updateURL()
                if (this.state.currentIndex == this.state.clipCache.length - 1) {
                    this.fetchClipFromServer();
                }
            });
        }
    }

    render() {
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];

        return (
            <div className='clipListingMobile'>
                {this.state.showAd
                    ? <>
                        <div style={{ position: 'absolute', top: '18px', left: '18px' }}>
                            <Link to='/' style={{ position: 'absolute' }}><i style={{ fontSize: '45px' }} className="fa fa-home home_link styled-icon"></i></Link>
                        </div>
                        <div className='text-center'>
                            <ClipAds spot={"-1"} clipId={currentClipData.clipListing.hpId} />
                        </div>
                    </>
                    : currentClipData && (
                        <>
                            <ClipContentMob listing={currentClipData.clipListing} />
                            <div className="row" style={{ color: '#ccc', justifyContent: 'space-between', paddingBottom: '10px', transform: 'translateY(-20px)' }}>
                                <div className="text" style={{ paddingLeft: '25px', fontWeight: '500' }}><i class='fas fa-angle-double-left'></i>Swipe</div>
                                <div className="text" style={{ paddingRight: '25px', fontWeight: '500' }}>Swipe<i class='fas fa-angle-double-right'></i></div>
                            </div>
                        </>

                    )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    is_login: state.is_login,
    listing_id: state.listing_id,
    viewedListings: state.viewedListings,
    search_data: state.search_data,
    clipadAds: state.clipadAds,
    visibleAds: state.visibleAds,
});

export default connect(mapStateToProps)(CLipShareListingMobile);
