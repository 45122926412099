import React, { useEffect, useState } from 'react';
import { VideoCardData } from './VideoCardData';
import styles from './cover.module.css';

export const ClipCardRow = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);

    return (
        <div className={`row pt-4 pb-5 ${styles.card_transition} ${loaded ? `${styles.show}` : ''}`}>
            {props.listings.map((listing) => (
                    <VideoCardData
                        {...props}
                        key={listing.hpId}
                        listing={listing}
                        isViewed={props.viewedContentIds.includes(listing.hpId)}
                    />
            ))}
        </div>
    );
};
