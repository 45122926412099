import React from 'react'
import styles from './uploadAnimation.module.css'

export const UploadAnimation = (props) => {
    return (
        <div id={props.id} className={`${styles.inline_upload_animation} ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}>
            {props.text ? props.text : props.children}
        </div>
    )
}