import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { BusinessListings } from './listings/home/BusinessListings';
import RecentPreBusinessListings from './listings/home/RecentPreBusinessListings';
import RecentAdvListings from './listings/home/RecentAdvListings';
import RecentVideoListing from './listings/home/RecentVideoListing'
import { SponsoredEventJob } from './listings/home/SponsoredEventJob';
import { SponsoredNewsArticle } from './listings/home/SponsoredNewsArticle';
import { Login } from './Authentication/Login/LoginPage';
import Registration from './Authentication/Registration/Registration';
import { OTP } from './Authentication/Registration/OTP';
import { LoginOTP } from './Authentication/Registration/LoginOTP';
import { Welcome } from "./Authentication/Registration/Welcome";
import { ForgotPassword } from './Authentication/Login/ForgotPassword';
import { ResendEmail } from './Authentication/Registration/ResendEmail';
import { TermsOfService } from "./footer/TermsOfService";
import { PrivacyPolicy } from "./footer/PrivacyPolicy";
import { CookiesPolicy } from "./footer/CookiesPolicy";
import { ViewsWork } from "./footer/ViewsWorks";
import { Promote } from './footer/Promote'
import { AdvertisementPolicy } from "./footer/AdvertisementPolicy";
import { ContentPolicy } from "./footer/ContentPolicy";
import { AboutUs } from "./footer/AboutUs";
import { Advt } from './footer/Advt';
import { Posts } from './footer/Posts';
import { Referral } from './footer/Referral';
import abecPages from './services/abecPages';
import listingService from './listings/service/listingService';
import AddPost from './listings/forms/AddPost'
import MetaDecor from './MetaDecor';
import { VideoListings } from './listings/home/VideoListings';
import { SponsoredVideo } from './listings/home/SponsoredVideo';
import { PhotosGuidlines } from './footer/PhotosGuidelines';
import { VideosGuidelines } from './footer/VideosGuidelines';
import { ReviewsGuidelines } from './footer/ReviewsGuidelines';
import { PagesGuidelines } from './footer/PagesGuidelines';
import { BusinessGuidelines } from './footer/BusinessGuidelines';
import { EventGuidelines } from './footer/EventGuidelines';
import { JobGuidelines } from './footer/JobGuidelines';
import knowledgeBase from './footer/KnowledgeBase';
import Qualification from './user_panel/Qualification';
import SuccessStory from './footer/SuccessStory';
import { HomeTabs } from './listings/tabs/HomeTabs';
import ViewWithdrawal from './user_panel/view_report/ViewWithdrawal';
import ViewEarning from './user_panel/view_report/ViewEarning';
import OfcAddress from './footer/OfcAddress';
import ClipListing from './listings/ClipListing';
import ClipShareListing from './listings/ClipShareListing';
import ClipListingMobile from './listings/ClipListingMobile';
import CLipShareListingMobile from './listings/CLipShareListingMobile';
import { RefundPolicy } from './footer/RefundPolicy';
import { Processing } from './processing';
import profileCover from './my_content/profileCover';
import { SuccessStoryArc } from './footer/SuccessStoryArc';
import Contents from './my_content/Contents';
import CategoryCover from './my_content/CategoryCover';

const Advertise = lazy(() => import('./advertisements/Advertise'));
const SingleListing = lazy(() => import('./listings/SingleListing'));
const Contact = lazy(() => import('../components/Contact'));
const HelpContent = lazy(() => import('./Help/Help-content'));
const HelpMobile = lazy(() => import('./Help/HelpMobile'));
const LookUp = lazy(() => import('./listings/lookup/LookUp'));
const ListingBasic = lazy(() => import("./listings/forms/ListingBasic"));
const Payment = lazy(() => import("./Payment"));
const ListingAdvance = lazy(() => import("./listings/forms/ListingAdvance"));
//const AddPost = lazy(() => import("./listings/forms/AddPost"));
const ManageProfile = lazy(() => import('./user_panel/ManageProfile'));
const ManagePosts = lazy(() => import('./user_panel/ManagePosts'));
const ManageAdvertisement = lazy(() => import('./user_panel/ManageAdvertisement'));
const Orders = lazy(() => import('./user_panel/Orders'));
const ViewReport = lazy(() => import('./user_panel/view_report/ViewReport'));
const ViewDownline = lazy(() => import('./user_panel/view_report/ViewDownline'));
const ListingPerformance = lazy(() => import('./user_panel/view_report/ListingPerformance'));
const AdvtPerformance = lazy(() => import('./user_panel/view_report/AdvtPerformance'));
const ReferralActivity = lazy(() => import('./user_panel/view_report/ReferralActivity'));


class Content extends Component {

    state = {
        isAdmin: false,
        listing_type: 'article',
        isListingLoading: false,
    }

    static getDerivedStateFromProps(props, state) {
        let update = {};
        if (props.isAdmin !== state.isAdmin) {
            update.isAdmin = props.isAdmin;
        }
        return update;
    }

    componentDidMount() {
        const page = abecPages.getPageName(this.props.match.path)
        console.log(page)
        if (this.props.content_page !== page && !this.isEmpty(this.props.current_coords)) {
            this.props.setContentPage(page)
            if (page === 'HomePage' || page === 'LookupPage') {
                listingService.getViewedListingIds().then(data => {
                    this.props.setViewedListings(data)
                })
            }
            if (page !== 'LookupPage' && page !== 'ListingPage' && page !== 'ClipsPage'  && this.props.lookUpPageState) {
                this.props.setLookUpPageState(null)
            }
        }
    }

    componentDidUpdate(nextProps, nextState) {
        const page = abecPages.getPageName(this.props.match.path)
        try {
            if (this.props.current_coords !== nextProps.current_coords || this.props.content_page !== page) {
                this.props.setContentPage(page)
                if (page === 'HomePage' || page === 'LookupPage' || page === 'ListingPage' || page === 'ClipsPage') {
                    listingService.getViewedListingIds().then(data => {
                        this.props.setViewedListings(data)
                    })
                }
                if (page !== 'LookupPage' && page !== 'ListingPage' && page !== 'ClipsPage' && this.props.lookUpPageState) {
                    this.props.setLookUpPageState(null)
                }
            }
        } catch {
            console.log("failed getting ad page " + page)
        }
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    tabs = (id) => {
        if (!id || this.state.listing_type === id) return;

        this.setState({ listing_type: id, isListingLoading: true });
    };

    render() {

        let sponsoredNewsRendered = false;
        let recentVideoRendered = false;
        if (this.props.isAdmin)
            return <Redirect to={'/admin-panel/dashboard'} />

        const page = abecPages.getPageName(this.props.match.path);
        const wrapperClass = 'pageContent col-md-12';
        const ListingWrapperClass = 'content col-md-12';
        const clipWrapperClass = 'clipContent';
        const formWrapperClass = 'formContent'
        const contentPage =
            <div className={page === 'ClipsPage' ? clipWrapperClass : page === 'MyContent' ? wrapperClass : page === 'LoginPage' || page === 'RegistrationPage' ? formWrapperClass : page === 'ListingPage' ? ListingWrapperClass : wrapperClass}>
                <MetaDecor></MetaDecor>
                <Route path="/" exact render={() => <HomeTabs onToggle={this.tabs} {...this.props} />} />
                {this.state.listing_type === 'business' && (
                    <>
                        <Route path="/" exact component={BusinessListings} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentPreBusinessListings {...props} listingType={'business'} bannerAdSpot={'-12'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentAdvListings {...props} listingType={'business'} bannerAdSpot={'-13'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Businesses to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'event' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredEventJob {...props} listingType={'Event'} bannerAdSpot={'-2'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentAdvListings {...props} listingType={'event'} bannerAdSpot={'-14'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Events to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'job' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredEventJob {...props} listingType={'Job'} bannerAdSpot={'-3'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentAdvListings {...props} listingType={'job'} bannerAdSpot={'-15'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Jobs to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'news' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredNewsArticle {...props} listingType={'news'} bannerAdSpot={'-6'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentVideoListing {...props} listingType={'news'} bannerAdSpot={'-10'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Pages to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'article' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredVideo {...props} listingType={'article'} bannerAdSpot={'-4'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <VideoListings {...props} listingType={'article'} bannerAdSpot={'-8'} /> }} />

                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredVideo {...props} listingType={'clips'} bannerAdSpot={'-19'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <VideoListings {...props} listingType={'clips'} bannerAdSpot={'-20'} /> }} />

                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Videos to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'reviews' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredNewsArticle {...props} listingType={'reviews'} bannerAdSpot={'-7'} /> }} />
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <RecentVideoListing {...props} listingType={'reviews'} bannerAdSpot={'-11'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Reviews to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {this.state.listing_type === 'photos' && (
                    <>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredNewsArticle {...props} listingType={'album'} bannerAdSpot={'-16'} /> }} />
                        <Route path="/" exact render={(props) => { recentVideoRendered = true; return <RecentVideoListing {...props} listingType={'album'} bannerAdSpot={'-17'} /> }} />
                        <br></br>
                        <Route path="/" exact render={(props) => { sponsoredNewsRendered = true; return <SponsoredNewsArticle {...props} listingType={'photos'} bannerAdSpot={'-5'} /> }} />
                        <Route path="/" exact render={(props) => { recentVideoRendered = true; return <RecentVideoListing {...props} listingType={'photos'} bannerAdSpot={'-9'} /> }} />
                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Photos to Display</div>
                                </Route>
                            </Switch>)} */}

                        {/* {!sponsoredNewsRendered && !recentVideoRendered && (
                            <Switch>
                                <Route exact path="/">
                                    <div className="homecard">No Active Photos to Display</div>
                                </Route>
                            </Switch>)} */}
                    </>
                )}

                {/* <Suspense fallback={<div>Loading Services...</div>}>
                    <Route path="/" exact render={(props) => <RecentListings {...props} listingType={'business'} bannerAdSpot={'-10'} />} />
                    <Route path="/" exact render={(props) => <RecentListings {...props} listingType={'event'} bannerAdSpot={'-11'} />} />
                    <Route path="/" exact render={(props) => <RecentListings {...props} listingType={'job'} bannerAdSpot={'-12'} />} />
                    <Route path="/" exact render={(props) => <RecentListings {...props} listingType={'article'} bannerAdSpot={'-13'} />} />
                    <Route path="/" exact render={(props) => <RecentListings {...props} listingType={'news'} bannerAdSpot={'-14'} />} />
                </Suspense> */}
                {/* <Route path="/single-listing/:hpId/:addressId" component={SingleListing} /> */}

                <Route path="/addpost" component={AddPost} />
                {this.props.isMobile ? <Route path="/clips/:b/:hpId" component={ClipListingMobile} /> : <Route path="/clips/:b/:hpId" component={ClipListing} />}
                {this.props.isMobile ? <Route path="/shareclips/:b/:hpId" component={CLipShareListingMobile} /> : <Route path="/shareclips/:b/:hpId" component={ClipShareListing} />}
                {/* <Route exact path="/contents/:username/:tab?" component={profileCover} /> */}
                <Route exact path="/categories/:username" component={profileCover} />
                <Route exact path="/content/:type/:subcategory/:tab" component={CategoryCover} />
                <Suspense fallback={<div>Loading Services...</div>}>
                    <Switch>
                        <Route path="/processing/:id" component={Processing} />
                        <Route path="/advertise" component={Advertise} />
                        <Route path="/l/:b/:hpId" component={SingleListing} />
                        <Route path="/contact" component={Contact} />
                        <Route path="/search" component={LookUp} />
                        <Route path="/listing-basic" component={ListingBasic} />
                        <Route path="/listing-advanced" component={ListingAdvance} />
                        <Route path="/listing-premium" component={ListingAdvance} />
                        <Route path="/payment" component={Payment} />
                        <Route path="/updateadvertisement/:id" component={Advertise} />
                        <Route exact path="/update-post/:id" component={AddPost} />
                        <Route exact path="/update-post/content/premium/:id" component={ListingAdvance} />
                        <Route exact path="/update-post/content/advanced/:id" component={ListingAdvance} />
                        <Route path="/order/:id" component={Payment} />
                    </Switch>
                </Suspense>

                <Suspense fallback={<div>Loading User Panel...</div>}>
                    {/**User Panel */}
                    <Switch>
                        <Route path="/manageprofile" component={ManageProfile} />
                        <Route path="/manageposts" component={ManagePosts} />
                        <Route path="/manageadvertisement" component={ManageAdvertisement} />
                        <Route path="/ad-order-history/:id" render={(props) => <Orders {...props} adOrder={true} />} />
                        <Route path="/post-order-history/:id" render={(props) => <Orders {...props} postOrder={true} />} />
                        <Route path="/subscription-orders/:id" render={(props) => <Orders {...props} membershipOrder={true} />} />
                        <Route path="/qualification" exact component={Qualification} />
                    </Switch>
                </Suspense>

                <Suspense fallback={<div>Loading Reports...</div>}>
                    {/**View Report */}
                    <Switch>
                        <Route path="/viewreport" component={ViewReport} />
                        <Route path="/view-downline" component={ViewDownline} />
                        <Route path="/withdrawal-history" component={ViewWithdrawal} />
                        <Route path="/earning-history" component={ViewEarning} />
                        <Route path="/listingperformance" component={ListingPerformance} />
                        <Route path="/advtperformance" component={AdvtPerformance} />
                        <Route path="/referralactivity" component={ReferralActivity} />
                    </Switch>
                </Suspense>

                {/* <Route path="/help" component={HelpContent} /> */}

                <Suspense fallback={<div>Loading Help Contents...</div>}>
                    <Route path="/help" component={HelpContent} />
                    {/* <Route path="/helpPage" component={HelpMobile} /> */}
                    <Route path="/helpPage" component={HelpMobile} />
                    {/* <Route path="/help" component={Example} /> */}
                </Suspense>

                {/**Login and Registration */}
                <Switch>
                    <Route path="/registration" component={Registration} />
                    <Route path="/otp/:verify" component={OTP} />
                    <Route path="/resendemail/:id" component={ResendEmail} />
                    <Route path="/activation-success/:userid" component={Welcome} />
                    <Route path="/login/:required" component={Login} />
                    <Route path="/login-otp/:verify" component={LoginOTP} />
                    <Route path="/forgotpassword" component={ForgotPassword} />
                </Switch>

                {/**Fotter */}
                <Switch>
                    <Route path="/terms-of-service" component={TermsOfService} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/cookies-policy" component={CookiesPolicy} />
                    <Route path="/advertisement-policy" component={AdvertisementPolicy} />
                    <Route exact path="/success-story" component={SuccessStory} />
                    <Route exact path="/success-story/archieve" component={SuccessStoryArc} />
                    <Route exact path="/content-policy" component={ContentPolicy} />
                    <Route exact path="/refund-policy" component={RefundPolicy} />
                    <Route exact path="/content-policy/aajubaaju-my-photos" component={PhotosGuidlines} />
                    <Route exact path="/content-policy/aajubaaju-my-videos" component={VideosGuidelines} />
                    <Route exact path="/content-policy/aajubaaju-my-reviews" component={ReviewsGuidelines} />
                    <Route exact path="/content-policy/aajubaaju-my-pages" component={PagesGuidelines} />
                    <Route exact path="/content-policy/business" component={BusinessGuidelines} />
                    <Route exact path="/content-policy/event" component={EventGuidelines} />
                    <Route exact path="/content-policy/job" component={JobGuidelines} />
                    <Route path="/about-us" component={AboutUs} />
                    <Route path="/knowledge-base" component={knowledgeBase} />
                    <Route path="/we-are-at" component={OfcAddress} />
                    <Route path="/advertisements" component={Advt} />
                    <Route path="/posts" component={Posts} />
                    <Route path="/referring" component={Referral} />
                    <Route path="/how-to-earn" component={ViewsWork} />
                    <Route path="/promote" component={Promote} />
                </Switch>
            </div>
        return (contentPage)
    }
}

const mapStateToProps = state => {
    return {
        isAdmin: state.isAdmin,
        content_page: state.content_page,
        current_coords: state.current_coords,
        lookUpPageState: state.lookUpPageState,
        isMobile: state.isMobile
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setContentPage: (page) => dispatch({ type: 'CONTENT_PAGE', content_page: page }),
        setViewedListings: (listingIds) => dispatch({ type: 'VIEWED_LISTINGS', viewedListings: listingIds }),
        setLookUpPageState: (data) => dispatch({ type: "LOOKUP_PAGE_STATE", lookUpPageState: data }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Content));


