import React, { useState } from 'react';
import Popup from "reactjs-popup";
import authservice from './Authentication/service/authservice';
import { Timer } from './utility/timer';
import mobilepopup from '../assets/mobilepopup.jpg'

function PreventMobilePopup(props) {
    const [suspendWarningTimeout, setSuspendWarningTimeout] = useState(false);
    const warningTimeOutInSeconds = 10;

    const onTimeOut = () => {
        //axios call to suspend account
        authservice.logOut();
        setSuspendWarningTimeout(true)
    }

    const style = {
        position: 'relative',
        background: 'rgb(255 255 255 / 100%)',
        width: '1250px',
        height: '660px',
        margin: 'auto',
        border: 'none', //4px solid #fffaf5',
        padding: '5px',
    }
    const verticleCenter = {
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        textAlign: 'center',
    }
    const text = {
        color: 'navy',
        fontWeight: 'bold',
    }
    const emoji = {
        objectFit: 'cover',
        height: '336px',
        width: '336px',
    }
    return (
        <Popup
            open={props.open}
            closeOnDocumentClick={false}
            closeOnEscape={false}
            //onClose={closePopup}
            lockScroll={true}
            contentStyle={style}
            overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
        >
            <div style={{ ...verticleCenter, ...text }}>
                <img className='img-fluid' style={emoji} src={mobilepopup} />
            </div>
        </Popup>
    )
}

export default PreventMobilePopup;