import React from 'react'
import styles from './redirect_link.module.css'



export const RedirectLink = (props) => {
    return (
      <span id={props.id} onClick={props.handleClick} className={`${styles.redirect_link} ${props.className ? props.className : ''}`}
      style={props.style ? props.style : null}>
        {props.text ? props.text : props.children}
      </span>
    );
   }