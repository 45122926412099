import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import listingService from '../listings/service/listingService';
import { CardsListings } from './CardsListings';

const PhotoCategory = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);

    const heading = {
        business: 'Business Listings',
        event: 'Event Listings',
        job: 'Job Listings',
        news: 'AajuBaaju My Pages',
        reviews: 'AajuBaaju My Reviews',
        article: 'AajuBaaju My Videos',
        photos: 'AajuBaaju My Photos',
        album: 'AajuBaaju My Album',
    }

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/l/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: null,
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        if (!coords.latitude)
            return;

        listingService.getMyPhotosByType(coords, props.listingType).then(data => {
            //.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
            setListings(data);
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <TitleHeading text={`${heading[props.listingType]}`} />
                <div className="row">
                    <CardsListings
                        listings={listings}
                        showContent={getListingDetails}
                        viewedContentIds={viewedListings}
                        type={props.listingType}
                    />
                </div>
            </div> : <></>
    )
}

export default PhotoCategory;