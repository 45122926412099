import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import { LinkButton } from '../UIElements/Link_Button/LinkButton';
import authservice from '../Authentication/service/authservice';
import '../user_panel/css/UserProfile.css'
import cover from '../../assets/cover.jpg'
import styles from './cover.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

class Contents extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: authservice.getCurrentUserName(),
            //myPages: '',
            allCat: '',
            allCategory: '',
            coverImageAll: '',
            // myVideos: '',
            // myClips: '',
            // myReviews: '',
            // myPhotos: '',
            // myAlbum: '',
            // myPageCategory: '',
            // myVideoCategory: '',
            // myClipCategory: '',
            // myPhotoCategory: '',
            // myAlbumCategory: '',
            // myReviewCategory: '',
            // coverImagePage: '',
            // coverImageVideo: '',
            // coverImageClip: '',
            // coverImageReview: '',
            // coverImagePhoto: '',
            // coverImageAlbum: '',
            loaded: false
        }
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loaded: true });
        }, 100);
        this._isMounted = true;
        this.getSubCategoryList();
        console.log(this.props.match)
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutId);
    }

    getSubCategoryList = () => {
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-pages-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const page = response.data
        //         const pagesData = page.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myPages: pagesData.map(item => item.subCategory),
        //             myPageCategory: pagesData.map(item => item.category),
        //             coverImagePage: pagesData.map(item => item.coverImage)
        //         });
        //     });
            axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-all-subcategory?userName=${this.props.match.params.username}`)
            .then(response => {
                const all = response.data
                const allData = all.map(item => ({
                    subCategory: item.subCategory,
                    category: item.category,
                    coverImage: item.coverImage
                }));

                this.setState({
                    allCat: allData.map(item => item.subCategory),
                    allCategory: allData.map(item => item.category),
                    coverImageAll: allData.map(item => item.coverImage)
                });
            });
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-videos-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const video = response.data
        //         const videosData = video.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myVideos: videosData.map(item => item.subCategory),
        //             myVideoCategory: videosData.map(item => item.category),
        //             coverImageVideo: videosData.map(item => item.coverImage)
        //         });
        //     });
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-clips-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const clip = response.data
        //         const clipsData = clip.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myClips: clipsData.map(item => item.subCategory),
        //             myClipCategory: clipsData.map(item => item.category),
        //             coverImageClip: clipsData.map(item => item.coverImage)
        //         });
        //     });

        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-reviews-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const review = response.data
        //         const reviewsData = review.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myReviews: reviewsData.map(item => item.subCategory),
        //             myReviewCategory: reviewsData.map(item => item.category),
        //             coverImageReview: reviewsData.map(item => item.coverImage)
        //         });
        //     });
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-photos-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const photo = response.data
        //         const photosData = photo.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myPhotos: photosData.map(item => item.subCategory),
        //             myPhotoCategory: photosData.map(item => item.category),
        //             coverImagePhoto: photosData.map(item => item.coverImage)
        //         });
        //     });
        // axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-album-subcategory?userName=${this.props.match.params.username}`)
        //     .then(response => {
        //         const album = response.data
        //         const albumData = album.map(item => ({
        //             subCategory: item.subCategory,
        //             category: item.category,
        //             coverImage: item.coverImage
        //         }));

        //         this.setState({
        //             myAlbum: albumData.map(item => item.subCategory),
        //             myAlbumCategory: albumData.map(item => item.category),
        //             coverImageAlbum: albumData.map(item => item.coverImage)
        //         });
        //     });

    }

    render() {
        // const clipUrlData = "Aajubaaju My Clips"
        // const clipUrl = clipUrlData.replace(/[\s]/gm, '-')
        // const videoUrlData = "Aajubaaju My Videos"
        // const videoUrl = videoUrlData.replace(/[\s]/gm, '-')
        // const pageUrlData = "Aajubaaju My Pages"
        // const pageUrl = pageUrlData.replace(/[\s]/gm, '-')
        // const photoUrlData = "Aajubaaju My Photos"
        // const photoUrl = photoUrlData.replace(/[\s]/gm, '-')
        // const albumUrlData = "Aajubaaju My Album"
        // const albumUrl = albumUrlData.replace(/[\s]/gm, '-')
        // const reviewUrlData = "Aajubaaju My Reviews"
        // const reviewUrl = reviewUrlData.replace(/[\s]/gm, '-')

        return (
            <>
                <TitleHeading className='headingLeftMargin' text={'AajuBaaju My Contents'} />
                <div className='row m-2'>
                    {this.state.allCat.length > 0 ? this.state.allCat.map((all, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImageAll[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImageAll[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/All/${all.replace(/[\s]/gm, '-')}/all`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={all} onClick={() => { this.props.history.push(`/content/All/${all.replace(/[\s]/gm, '-')}/all`) }}>{all}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                {/* <div className='row m-2'>
                    {this.state.myVideos.length > 0 ? this.state.myVideos.map((video, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImageVideo[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImageVideo[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${videoUrl}/${video.replace(/[\s]/gm, '-')}/videos`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={video} onClick={() => { this.props.history.push(`/content/${videoUrl}/${video.replace(/[\s]/gm, '-')}/videos`) }}>{video}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                <hr></hr>
                <TitleHeading className='headingLeftMargin' text={'Aajubaaju My Clips'} />
                <div className='row m-2'>
                    {this.state.myClips.length > 0 ? this.state.myClips.map((clip, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImageClip[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImageClip[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${clipUrl}/${clip.replace(/[\s]/gm, '-')}/clips`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={clip} onClick={() => { this.props.history.push(`/content/${clipUrl}/${clip.replace(/[\s]/gm, '-')}/clips`) }}>{clip}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                <hr></hr>
                <TitleHeading className='headingLeftMargin' text={'Aajubaaju My Photos'} />
                <div className='row m-2'>
                    {this.state.myPhotos.length > 0 ? this.state.myPhotos.map((photo, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImagePhoto[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImagePhoto[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${photoUrl}/${photo.replace(/[\s]/gm, '-')}/photos`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={photo} onClick={() => { this.props.history.push(`/content/${photoUrl}/${photo.replace(/[\s]/gm, '-')}/photos`) }}>{photo}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                <hr></hr>
                <TitleHeading className='headingLeftMargin' text={'Aajubaaju My Album'} />
                <div className='row m-2'>
                    {this.state.myAlbum.length > 0 ? this.state.myAlbum.map((album, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImageAlbum[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImageAlbum[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${albumUrl}/${album.replace(/[\s]/gm, '-')}/album`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={album} onClick={() => { this.props.history.push(`/content/${albumUrl}/${album.replace(/[\s]/gm, '-')}/album`) }}>{album}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                <hr></hr>
                <TitleHeading className='headingLeftMargin' text={'Aajubaaju My Pages'} />
                <div className='row m-2'>
                    {this.state.myPages.length > 0 ? this.state.myPages.map((page, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImagePage[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImagePage[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${pageUrl}/${page.replace(/[\s]/gm, '-')}/pages`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={page} onClick={() => { this.props.history.push(`/content/${pageUrl}/${page.replace(/[\s]/gm, '-')}/pages`) }}>{page}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div>
                <hr></hr>
                <TitleHeading className='headingLeftMargin' text={'Aajubaaju My Reviews'} />
                <div className='row m-2'>
                    {this.state.myReviews.length > 0 ? this.state.myReviews.map((review, index) => (
                        <div className='col-lg-3 col-6 col-md-3 col-sm-6 p-2 mb-2' key={index}>
                            <div className={`card ${styles.contentCard} ${styles.card_transition} ${this.state.loaded ? `${styles.show}` : ''}`}>
                                <img
                                    className={styles.card_img_top}
                                    alt="CoverImage"
                                    loading="lazy"
                                    src={this.state.coverImageReview[index] !== null ? `${process.env.REACT_APP_IMAGES_URL + this.state.coverImageReview[index]}` : cover}
                                    onClick={() => { this.props.history.push(`/content/${reviewUrl}/${review.replace(/[\s]/gm, '-')}/reviews`) }}
                                />
                                <div className='card-body p-1'>
                                    <ul className='list-unstyled'>
                                        <li className='text-center'>
                                            <Link className={styles.contentText} id={review} onClick={() => { this.props.history.push(`/content/${reviewUrl}/${review.replace(/[\s]/gm, '-')}/reviews`) }}>{review}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )) : <span>No Content Posted</span>}
                </div> */}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_login: state.is_login,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearPropsData: () => dispatch({ type: 'CLEAR_DATA' }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Contents);