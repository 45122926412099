import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import 'antd/dist/antd.css';
import Loader from '../utility/Loader';
import { ClipContent } from './ClipContent';
import authservice from '../Authentication/service/authservice';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import debounce from 'lodash/debounce';
import ClipAds from '../advertisements/ClipAds';

class ClipListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clipCache: [],
            subCategories: [],
            currentIndex: 0,
            error: null,
            showAd: false,
            numberOfDownSwap: 1,
            isNextButtonDisabled: false,
            loadAd: false,
            isDialogOpen: false,
        };
        this.viewTimer = null;
        this.shareLinkRef = React.createRef();
    }

    componentDidMount() {
        this.clearClip()
        window.scrollTo(0, 0);
        this.callClipDetails(this.props.match.params.hpId);
        window.addEventListener('keydown', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.currentIndex !== prevState.currentIndex) {
            window.scrollTo(0, 0);
        }

    }

    handleScroll = debounce((event) => {
        event.preventDefault();
        const isArrowLeft = event.key === 'ArrowLeft';
        const isArrowRight = event.key === 'ArrowRight';
        if (isArrowRight) {
            this.handleNext();
        } else if (isArrowLeft) {
            this.loadPrevClip();
        }
    }, 200);


    clearClip = () => {
        if (this.props.is_login) {
            axios.post(process.env.REACT_APP_BACKEND_URL + `/user-activity/clear-trash-memory?${'userId=' + authservice.getCurrentUserid()}`).then(response => {
                this.setState({ clipListing: response.data });
            }).catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data })
            })
        }
        else {
            axios.post(process.env.REACT_APP_BACKEND_URL + `/guest-activity/clear-trash-memory?${'guestId=' + authservice.getCookie('hp.rocks.soon')}`).then(response => {
                this.setState({ clipListing: response.data });
            }).catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data })
            })
        }
    }

    updateURL = () => {
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];
        if (currentClipData && currentClipData.clipListing && currentClipData.clipListing.businessName) {
            const businessName = currentClipData.clipListing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
            this.props.history.push(`/clips/${businessName}/${currentClipData.clipListing.hpId}`);
        }
    }

    callClipDetails = (hpId) => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getlistings/${hpId}?${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`;

        axios.get(url)
            .then(response => {
                const clipCache = [];
                clipCache.push({
                    clipListing: response.data,
                    subCategories: response.data.subCategories
                });
                this.setState({ subCategories: response.data.subCategories })
                this.setState({ clipCache }, () => this.fetchClipFromServer()); // Call loadNextClip after setting initial clip data

            })
            .catch(error => {
                Loader.stopSpinner();
                if (error.response)
                    this.setState({ error: error.response.data });
            });
    }

    fetchClipFromServer = () => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/get-next-clips?${this.props.is_login ? 'user_id=' + authservice.getCurrentUserid() : 'guest_id=' + authservice.getCookie('hp.rocks.soon')}`)
            .then(response => {
                const nextClipListing = response.data;
                sessionStorage.removeItem("clipad")
                if (nextClipListing && nextClipListing.subCategories && nextClipListing.subCategories.length > 0) {
                    const nextClipData = {
                        clipListing: nextClipListing,
                        subCategories: nextClipListing.subCategories
                    };
                    this.setState(prevState => ({
                        clipCache: [...prevState.clipCache, nextClipData]
                    }));
                } else {
                    console.log("No next clips available.");
                }
            })
            .catch(error => {
                Loader.stopSpinner();
                console.error('Error fetching next clip:', error);
                if (error.response)
                    this.setState({ error: error.response.data });
            });
    }

    loadPrevClip = () => {
        this.setState(prevState => ({
            currentIndex: Math.max(prevState.currentIndex - 1, 0)
        }), () => this.updateURL());
    }

    loadNextClip = () => {
        sessionStorage.removeItem("clipad")
        console.log(this.state.currentIndex !== this.state.clipCache.length - 2)
        if (!this.state.showAd && this.state.currentIndex === this.state.clipCache.length - 1) {
            this.setState({ showAd: true })
            // this.props.history.push(`/clips/ad/0`);
            return
        }
        if (!this.state.showAd && this.state.numberOfDownSwap % 6 == 0) {
            this.setState({ showAd: true })
            // this.props.history.push(`/clips/ad/0`);
            return;
        }

        if (this.state.currentIndex < this.state.clipCache.length - 1) {
            console.log("loading from cache")
            this.setState(prevState => ({
                currentIndex: prevState.currentIndex + 1
            }), () => {
                this.updateURL()
                if (this.state.currentIndex == this.state.clipCache.length - 1) {
                    this.fetchClipFromServer();
                }
            });
        }
    }

    handleNext = () => {
        this.setState({ showAd: false });
        this.setState(prevState => ({
            numberOfDownSwap: prevState.numberOfDownSwap + 1
        }), () => this.loadNextClip());
    }

    copyToClipboard = () => {
        const shareLink = this.shareLinkRef.current;
        shareLink.select();
        document.execCommand('copy');
    };

    showShareDialog = () => {
        this.setState({ isDialogOpen: true });
    };

    hideShareDialog = () => {
        this.setState({ isDialogOpen: false });
    };

    shareTo = (platform) => {
        const { clipCache, currentIndex } = this.state;
        const currentClipData = clipCache[currentIndex];
        const businessName = currentClipData.clipListing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const shareData = {
            title: `Check out ${businessName}`,
            url: `${window.location.origin}/shareclips/${businessName}/${currentClipData.clipListing.hpId}`
        };

        let shareUrl;
        switch (platform) {
            case 'facebook':
                shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}`;
                break;
            case 'whatsapp':
                shareUrl = `https://wa.me/?text=${encodeURIComponent(shareData.text + ' ' + shareData.url)}`;
                break;
            case 'twitter':
                shareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareData.text)}&url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'skype':
                shareUrl = `https://web.skype.com/share?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'telegram':
                shareUrl = `https://t.me/share/url?url=${encodeURIComponent(shareData.url)}&text=${encodeURIComponent(shareData.text)}`;
                break;
            case 'snapchat':
                shareUrl = `https://www.snapchat.com/share?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'linkedin':
                shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareData.url)}`;
                break;
            case 'gmail':
                shareUrl = `mailto:?subject=${encodeURIComponent(shareData.title)}&body=${encodeURIComponent(shareData.text + ' ' + shareData.url)}`;
                break;
            default:
                return;
        }
        window.open(shareUrl, '_blank');
        this.hideShareDialog();
    };

    render() {
        const { clipCache, currentIndex, isDialogOpen } = this.state;
        const currentClipData = clipCache[currentIndex];
        const clipUrlData = "Aajubaaju My Clips"
        const clipUrl = clipUrlData.replace(/[\s]/gm, '-')
        return (
            <>
                <div className='billBoard'>
                    <div className="row clipContent">
                        {this.state.clipCache.length === 0 ?
                            <div className='col-md-3 text-right nextBtn'> </div> :
                            <div className='col-md-3 text-right nextBtn'>
                                <i onClick={this.loadPrevClip} className="fa fa-chevron-left prevNext"></i>
                            </div>}
                        <div className='col-md-6 text-center'>
                            {this.state.showAd ? (
                                <div className='col-md-12 text-center' style={{marginTop:'12px'}}>
                                    <ClipAds spot={"-1"} />
                                </div>
                            ) : (
                                <>
                                    {currentClipData && <> <div className='col-md-12 text-center'>
                                        <ClipContent listing={currentClipData.clipListing} />
                                    </div>
                                        <div className='row col-md-12 text-center clip'>
                                            <div className='col-md-3'></div>
                                            <div className='col-md-3 text-left'>
                                                {currentClipData.subCategories.map((subcategory) => (
                                                    <Link
                                                        key={subcategory.id}
                                                        to={`/content/${clipUrl}/${subcategory.subCategory.replace(/[\s]/gm, '-')}/clips`}
                                                        className="watch-button"
                                                        title="{`Watch all`}"
                                                    >
                                                        Watch All
                                                    </Link>
                                                ))}
                                            </div>
                                            <div className='col-md-3 text-right'>
                                                <div>
                                                    <Link className="clip-button" type="button" onClick={this.showShareDialog}>Share</Link>
                                                    {isDialogOpen && (
                                                        <div className="sharedialog">
                                                            <h2>Share this Clip</h2>
                                                            <input
                                                                type="text"
                                                                value={`${window.location.origin}/shareclips/${currentClipData.clipListing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')}/${currentClipData.clipListing.hpId}`}
                                                                ref={this.shareLinkRef}
                                                                style={{ width: '80%', padding: '5px', marginBottom: '10px' }}
                                                                readOnly
                                                            />
                                                            <button className="copylink" onClick={this.copyToClipboard}><i className="fa fa-copy"></i></button>
                                                            <div className="sharebuttons">
                                                                <div className="sharebutton fb" onClick={() => this.shareTo('facebook')}></div>
                                                                <div className="sharebutton whatsapp" onClick={() => this.shareTo('whatsapp')}></div>
                                                                <div className="sharebutton twitter" onClick={() => this.shareTo('twitter')}></div>
                                                                <div className="sharebutton skype" onClick={() => this.shareTo('skype')}></div>
                                                                <div className="sharebutton telegram" onClick={() => this.shareTo('telegram')}></div>
                                                                <div className="sharebutton snapchat" onClick={() => this.shareTo('snapchat')}></div>
                                                                <div className="sharebutton linkedin" onClick={() => this.shareTo('linkedin')}></div>
                                                                <div className="sharebutton gmail" onClick={() => this.shareTo('gmail')}></div>
                                                            </div>
                                                            <button className="shareclose" onClick={this.hideShareDialog}>Close</button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className='col-md-3'></div>
                                        </div>
                                    </>}
                                </>
                            )}

                        </div>
                        {this.state.isNextButtonDisabled ? null :
                            <div className='col-md-3 text-left nextBtn'>
                                <i className="fa fa-chevron-right prevNext" onClick={this.handleNext}></i>
                            </div>}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_login: state.is_login,
        listing_id: state.listing_id,
        viewedListings: state.viewedListings,
        search_data: state.search_data,
        clipadAds: state.clipadAds,
    };
};

export default connect(mapStateToProps)(ClipListing);
