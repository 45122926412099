import React, { useEffect, useState } from 'react'
import { Errors } from './UIElements/errors/Errors';
import Select from 'react-select';

export const SelectAdvertiseFilter = (props) => {

    const [error, setError] = useState('');

    useEffect(() => {
        return () => {
            if (props.id === 'State')
                props.setStateValidationMethod && props.setStateValidationMethod(0)
            if (props.id === 'City')
                props.setCityValidationMethod && props.setCityValidationMethod(0)
            if (props.id === 'Locality')
                props.setLocalityValidationMethod && props.setLocalityValidationMethod(0)
            if (props.id === 'Neighbourhood')
                props.setNeighbourhoodValidationMethod && props.setNeighbourhoodValidationMethod(0)
        }
    }, []);

    useEffect(() => {
        if (props.id === 'State')
            props.setStateValidationMethod && props.setStateValidationMethod(props.options.length)
        if (props.id === 'City')
            props.setCityValidationMethod && props.setCityValidationMethod(props.options.length)
        if (props.id === 'Locality')
            props.setLocalityValidationMethod && props.setLocalityValidationMethod(props.options.length)
        if (props.id === 'Neighbourhood')
            props.setNeighbourhoodValidationMethod && props.setNeighbourhoodValidationMethod(props.options.length)
    }, [props])

    const handleChange = (e) => {
        let selctedList = []
        if (props.isMulti) {
            if (Array.isArray(e)) selctedList = e
            else selctedList = [...props.selectedItems, e]
        } else if (e) {
            selctedList.push(e)
        }
        if (props.onChange)
            props.onChange(selctedList)

        if (error && selctedList.length > 0)
            setError('')
    }

    return (
        <>
            <div className={`form-row ${props.wrapperClass}`}>
                <div id={props.id} className="form-group col-md-12">
                    <Select
                        placeholder={'Select Your ' + props.id}
                        value={props.selectedItems}
                        onChange={handleChange}
                        options={props.options}
                        isMulti={props.isMulti}
                        isDisabled={props.disabled}
                    />
                    <Errors id="cateErrorInfo">{error}</Errors>
                </div>
            </div>
        </>
    )
}