import { Component } from 'react';
import axios from 'axios';
import authservice from '../../Authentication/service/authservice';

const listingUrl = process.env.REACT_APP_BACKEND_URL + '/hpmgr/listing';

class ListingService {
    // async getAllListingsByType(current_coords, type) {
    //     return axios.get(`${listingUrl}/getall?type=${type}&latitude=${current_coords.latitude}&longitude=${current_coords.longitude}&country=${current_coords.visitorCountry}&state=${current_coords.visitorState}`)
    //         .then(getAllListings => {
    //             return getAllListings.data
    //         }).catch(error => {
    //             console.log(error)
    //             return [];
    //         });
    // }

    async getAllListingsContent(current_coords, type) {
        const urlParts = window.location.pathname.split('/');
        const subCatIndex = urlParts.indexOf('content') + 2
        const subcat = urlParts[subCatIndex]
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            subCategory: [subcat.replace(/-/g, ' ')]
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    // async getAllSubData(current_coords) {
    //     const urlParts = window.location.pathname.split('/');
    //     const userNameIndex = urlParts.indexOf(window.location.origin) + 2;
    //     const subcategory = urlParts[userNameIndex];
    //     const searchDTO = {
    //         country: current_coords.visitorCountry,
    //         // userId: authservice.getCurrentUserid(),
    //         visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
    //         visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
    //         visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
    //         visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
    //         guestId: authservice.getCookie('hp.rocks.soon'),
    //         longitude: current_coords.longitude,
    //         latitude: current_coords.latitude,
    //         subcategory : subcategory,
    //     }
    //     return axios.get(`${listingUrl}/${subcategory}/all`, searchDTO)
    //         .then(getAllListings => {
    //             return getAllListings.data
    //         }).catch(error => {
    //             console.log(error)
    //             return [];
    //         });
    // }

    async getAllSubCatVideoContent(current_coords, type, myVideos, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myVideos,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllSubCatClipsContent(current_coords, type, myClips, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myClips,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllSubCatPagesContent(current_coords, type, myPages, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myPages,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllSubCatPhotosContent(current_coords, type, myPhotos, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myPhotos,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllSubCatAlbumContent(current_coords, type, myAlbum, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myAlbum,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllSubCatReviewsContent(current_coords, type, myReviews, offset = 0) {
        const urlParts = window.location.pathname.split('/');
        const userNameIndex = urlParts.indexOf('contents') + 1;
        const userName = urlParts[userNameIndex];
        const searchDTO = {
            country: current_coords.visitorCountry,
            // userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,
            userName: userName,
            subCategory: myReviews,
            offset: offset
        }
        return axios.post(`${listingUrl}/get-all-contents?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getAllListingsByType(current_coords, type) {
        const searchDTO = {
            country: current_coords.visitorCountry,
            userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,

        }
        return axios.post(`${listingUrl}/v2-getall?type=${type}`, searchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getMyPagesByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/pages`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }

    async getMyReviewsByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/reviews`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }

    async getMyAlbumByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/album`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }
    
    async getMyPhotosByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/photos`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }

    async getMyVideosByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/videos`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }

    async getMyClipsByType() {
        const urlParts = window.location.pathname.split('/');
        const subcategoryIndex = urlParts.indexOf(window.location.origin) + 4;
        const subcategory = urlParts[subcategoryIndex];
        return axios.get(process.env.REACT_APP_BACKEND_URL + `/hpmgr/listing/${subcategory}/clips`).then(getAllListings => {
            return getAllListings.data
        }).catch(error => {
            console.log(error)
            return [];
        });
    }

    // async getRecentListingsByType(current_coords, type) {
    //     return axios.get(`${listingUrl}/recent?type=${type}&country=${current_coords.visitorCountry}&state=${current_coords.visitorState}`)
    //         .then(getAllListings => {
    //             return getAllListings.data
    //         }).catch(error => {
    //             console.log(error)
    //             return [];
    //         });
    // }

    async getRecentListingsByType(current_coords, type) {
        const SearchDTO = {
            country: current_coords.visitorCountry,
            userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,

        }
        return axios.post(`${listingUrl}/v2-recent?type=${type}`, SearchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    // async getRecentPreListingsByType(current_coords, type) {
    //     return axios.get(`${listingUrl}/recent-pre?type=${type}&country=${current_coords.visitorCountry}&state=${current_coords.visitorState}`)
    //         .then(getAllListings => {
    //             return getAllListings.data
    //         }).catch(error => {
    //             console.log(error)
    //             return [];
    //         });
    // }

    async getRecentPreListingsByType(current_coords, type) {
        const SearchDTO = {
            country: current_coords.visitorCountry,
            userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,

        }
        return axios.post(`${listingUrl}/v2-recent-pre?type=${type}`, SearchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    async getRecentAdvListingsByType(current_coords, type) {
        const SearchDTO = {
            country: current_coords.visitorCountry,
            userId: authservice.getCurrentUserid(),
            visitorsState: (localStorage.getItem('state') ? localStorage.getItem('state') : current_coords.visitorState),
            visitorsCity: (localStorage.getItem('city') ? localStorage.getItem('city') : null),
            visitorsLocality: (localStorage.getItem('locality') ? localStorage.getItem('locality') : null),
            visitorsNeighbourhood: (localStorage.getItem('neighbourhood') ? localStorage.getItem('neighbourhood') : null),
            guestId: authservice.getCookie('hp.rocks.soon'),
            longitude: current_coords.longitude,
            latitude: current_coords.latitude,

        }
        return axios.post(`${listingUrl}/v2-recent-advance?type=${type}`, SearchDTO)
            .then(getAllListings => {
                return getAllListings.data
            }).catch(error => {
                console.log(error)
                return [];
            });
    }

    // async getRecentAdvListingsByType(current_coords, type) {
    //     return axios.get(`${listingUrl}/recent-advance?type=${type}&country=${current_coords.visitorCountry}&state=${current_coords.visitorState}`)
    //         .then(getAllListings => {
    //             return getAllListings.data
    //         }).catch(error => {
    //             console.log(error)
    //             return [];
    //         });
    // }

    async getViewedListingIds() {
        const userId = authservice.getCurrentUserid()
        if (userId !== null)
            return axios.get(`${process.env.REACT_APP_BACKEND_URL}/user-activity/get-viewed-listings?userId=${userId}`)
                .then(resp => {
                    return resp.data;
                }).catch(err => {
                    return [];
                })
        else {
            return axios.get(`${process.env.REACT_APP_BACKEND_URL}/guest-activity/get-viewed-listings?guestId=${authservice.getCookie('hp.rocks.soon')}`)
                .then(resp => {
                    return resp.data;
                }).catch(err => {
                    return [];
                })
        }
    }

    async updateListingViews(section, listings) {
        if (listings && listings.length) {
            let listingIds = [];
            listings.forEach(l => { listingIds.push(l.hpId) })
            const data = {
                section: section,
                listingIds: listingIds,
            }
            return axios.post(`${listingUrl}/update-views`, data).catch(err => {
                console.log(err)
            })
        }
    }

    // async updateSingleListingViews(section, listing) {
    //     if (listing.hpId) {
    //         return axios.put(`${listingUrl}/update-views/v2?listingId=${listing.hpId}&section=${section}`).catch(err => {
    //             console.log(err)
    //         })
    //     }
    // }

    getListingSettings(listing, current_coords) {
        if (!listing.categories)
            return {};

        let listingOrder = 'free';
        if (listing.businessPackage === "premium" || listing.businessPackage === "advanced")
            listingOrder = 'paid';
        else if ((listing.listingType === "news" || listing.listingType === "photos" || listing.listingType === "album" || listing.listingType === "reviews" || listing.listingType === "article" || listing.listingType === "clips") && (listing.featured || listing.sponsored))
            listingOrder = 'paid';

        return {
            ...current_coords,
            categories: listing.categories,
            subCategories: listing.subCategories,
            state: listing.states,
            city: listing.cities,
            locality: listing.localities,
            neighbourhood: listing.neighbourhoods,
            listingPackage: listingOrder,
            visitorLocation: true,
        }
    }

    getListingDTO(post_data) {
        const listing = {
            hpId: (post_data.hpId) ? post_data.hpId : null,
            listingType: post_data.listingType,
            businessPackage: post_data.businessPackage,
            categories: post_data.categories,
            subCategories: post_data.subCategories,
            country: "India",
            states: post_data.states,
            cities: post_data.cities,
            localities: post_data.localities,
            neighbourhoods: post_data.neighbourhoods,
            thumbnailImage: post_data.thumbnailImage,
            thumbnailDescription: post_data.thumbnailDescription,
            businessName: post_data.businessName,
            specializedIn: post_data.specializedIn ? post_data.specializedIn : null,
            advance_content: post_data.advance_content,
            businessAddress: post_data.businessAddress,
            deletedAddresses: post_data.deletedAddresses,
            emailAddress: post_data.emailAddress,
            website: post_data.website,
            listingStatus: post_data.listingStatus,
            userId: authservice.getCurrentUserid(),
        }

        if (listing.businessPackage === 'basic') {
            listing.contactPerson = post_data.contactPerson
            listing.contactNumber = post_data.contactNumber
        }

        if (listing.businessPackage === 'advanced') {
            listing.featuredImage = post_data.featuredImage
        }

        if ("news" === listing.listingType || "reviews" === listing.listingType || "article" === listing.listingType || "clips" === listing.listingType || "photos" === listing.listingType || "album" === listing.listingType) {
            listing.elements = post_data.elements
            listing.mypages_content = post_data.mypages_content
            listing.myvideos_content = post_data.myvideos_content
            listing.deletedElements = post_data.deletedElements
        } else if ("event" === listing.listingType || "job" === listing.listingType) {
            listing.dateAndTimeElements = post_data.dateAndTimeElements
            listing.companyName = post_data.companyName
            listing.companyDescription = post_data.companyDescription
            listing.organizerName = post_data.organizerName
            listing.organizerAddress = post_data.organizerAddress
            listing.organizerPerson = post_data.organizerPerson
            listing.organizerEmail = post_data.organizerEmail
            listing.organizerLandmark = post_data.organizerLandmark
            listing.organizerPhone = post_data.organizerPhone
            listing.organizerWebsite = post_data.organizerWebsite
            listing.experience = post_data.experience
            listing.salary = post_data.salary
            listing.deletedDateTimeElements = post_data.deletedDateTimeElements
            listing.deletedDateAndTimeElements = post_data.deletedDateAndTimeElements
        } else if ("business" === listing.listingType && listing.businessPackage !== 'basic') {
            listing.workingHours = [
                {
                    day: "Monday",
                    openingTime: post_data.mondayOpen,
                    closingTime: post_data.mondayClose,
                },
                {
                    day: "Tuesday",
                    openingTime: post_data.tuesdayOpen,
                    closingTime: post_data.tuesdayClose,
                },
                {
                    day: "Wednesday",
                    openingTime: post_data.wednesdayOpen,
                    closingTime: post_data.wednesdayClose,
                },
                {
                    day: "Thursday",
                    openingTime: post_data.thursdayOpen,
                    closingTime: post_data.thursdayClose,
                },
                {
                    day: "Friday",
                    openingTime: post_data.fridayOpen,
                    closingTime: post_data.fridayClose,
                },
                {
                    day: "Saturday",
                    openingTime: post_data.saturdayOpen,
                    closingTime: post_data.saturdayClose,
                },
                {
                    day: "Sunday",
                    openingTime: post_data.sundayOpen,
                    closingTime: post_data.sundayClose,
                },
            ]

            if (listing.businessPackage === 'premium') {
                listing.sliderImages = post_data.sliderImages
                // listing.galleryImages = post_data.galleryImages
                // listing.deletedGalleryImages = post_data.deletedGalleryImages
            }
        }
        return listing;
    }
}
export default new ListingService();