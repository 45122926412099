import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import BillBoard from '../advertisements/BillBoard';
import { connect } from 'react-redux'
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import { LinkButton } from '../UIElements/Link_Button/LinkButton';
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';
import authservice from '../Authentication/service/authservice';
import './css/UserProfile.css'
import Popup from "reactjs-popup";
import styles from '../listings/forms/addpost.module.css';
import LargeRectangle from '../advertisements/LargeRectangle';

class Qualification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            myPages: false,
            myVideos: false,
            myReviews: false,
            myPhotos: false,
            myAlbum: false,
            myClips: false,
            isPagesPopupOpen: false,
            isVideosPopupOpen: false,
            isReviewsPopupOpen: false,
            isPhotosPopupOpen: false,
            isAlbumPopupOpen: false,
            isClipsPopupOpen: false,
        }
    }

    componentDidMount() {
        this.handlQualification()
    }

    applyPagePopup = () => {
        this.setState((prevState) => ({
            isPagesPopupOpen: !prevState.isPagesPopupOpen,
        }));
    };
    applyVideoPopup = () => {
        this.setState((prevState) => ({
            isVideosPopupOpen: !prevState.isVideosPopupOpen,
        }));
    };
    applyReviewPopup = () => {
        this.setState((prevState) => ({
            isReviewsPopupOpen: !prevState.isReviewsPopupOpen,
        }));
    };
    applyPhotoPopup = () => {
        this.setState((prevState) => ({
            isPhotosPopupOpen: !prevState.isPhotosPopupOpen
        }));
    };
    applyAlbumPopup = () => {
        this.setState((prevState) => ({
            isAlbumPopupOpen: !prevState.isAlbumPopupOpen
        }));
    };
    applyClipPopup = () => {
        this.setState((prevState) => ({
            isClipsPopupOpen: !prevState.isClipsPopupOpen
        }));
    };


    handlQualification = () => {
        const userId = authservice.getCurrentUserid()
        axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/user/get-content-approval-status/${userId}`)
            .then((response) => {
                const pages = response.data.approvalForMyPages
                this.setState({ myPages: pages })

                const videos = response.data.approvalForMyVideos
                this.setState({ myVideos: videos })

                const reviews = response.data.approvalForMyReviews
                this.setState({ myReviews: reviews })

                const photos = response.data.approvalForMyPhotos
                this.setState({ myPhotos: photos })

                const album = response.data.approvalForMyAlbum
                this.setState({ myAlbum: album })

                const clips = response.data.approvalForMyClips
                this.setState({ myClips: clips })
            })
            .catch((error) => {
                console.error(error);
            });
    };


    render() {
        return (
            <>
                <div className="row col-md-12 text-center">
                    <div className="col-md-4"></div>
                    <div className='col-md-4 text-left'>
                        <TitleHeading style={{ marginTop: '20px', marginBottom: '20px', fontSize: '24px' }} text={'Qualification For Contents'} />
                        <ul style={{ fontSize: '22px' }}>
                            {console.log(this.state.myPages)}
                            {this.state.myPages ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Pages&nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Pages <LinkButton handleClick={this.applyPagePopup}>How to Apply</LinkButton></p>
                                {this.state.isPagesPopupOpen && (
                                    <Popup
                                        open={this.state.isPagesPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Pages:'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit an article written in MS Word Document following the guidelines in <Link to='/help/pagesguide'>https://aajubaaju.in/help/pagesguide </Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Pages qualification has only 1 attempt.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyPagePopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}

                            {this.state.myVideos ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Videos &nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Videos <LinkButton handleClick={this.applyVideoPopup}>How to Apply</LinkButton></p>
                                {this.state.isVideosPopupOpen && (
                                    <Popup
                                        open={this.state.isVideosPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Videos'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Video link following the guidelines in <Link to='/help/videosguide'>https://aajubaaju.in/help/videosguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Videos qualification has only 3 attempts.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyVideoPopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}

                            {this.state.myReviews ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Reviews&nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Reviews <LinkButton handleClick={this.applyReviewPopup}>How to Apply</LinkButton></p>
                                {this.state.isReviewsPopupOpen && (
                                    <Popup
                                        open={this.state.isReviewsPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Reviews'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Review written in MS Word Document following the guidelines in <Link to='/help/reviewsguide'>https://aajubaaju.in/help/reviewsguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Reviews qualification has only 1 attempt.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyReviewPopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}

                            {this.state.myPhotos ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Photos&nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Photos <LinkButton handleClick={this.applyPhotoPopup}>How to Apply</LinkButton></p>
                                {this.state.isPhotosPopupOpen && (
                                    <Popup
                                        open={this.state.isPhotosPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Photos'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Photo Story by sending a sequence of minimum 10 photos following the guidelines in <Link to='/help/photosguide'>https://aajubaaju.in/help/photosguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Photos qualification has only 3 attempts.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyPhotoPopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}

                            {this.state.myAlbum ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Album&nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Album <LinkButton handleClick={this.applyAlbumPopup}>How to Apply</LinkButton></p>
                                {this.state.isAlbumPopupOpen && (
                                    <Popup
                                        open={this.state.isAlbumPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Album'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Photo Album by sending a sequence of minimum 10 photos following the guidelines in <Link to='/help/albumguide'>https://aajubaaju.in/help/albumguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Album qualification has only 3 attempts.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyAlbumPopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}

                            {this.state.myClips ? <li>
                                <p><i className="fa fa-check-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Clips &nbsp;<i style={{ fontSize: '16px', color: '#138808', fontWeight: '700' }}>Qualified</i></p>
                            </li> : <li>
                                <p><i className="fa fa-square-o" aria-hidden="true"></i>&nbsp; &nbsp;AajuBaaju My Clips <LinkButton handleClick={this.applyClipPopup}>How to Apply</LinkButton></p>
                                {this.state.isClipsPopupOpen && (
                                    <Popup
                                        open={this.state.isClipsPopupOpen}
                                        overlayStyle={{ background: 'rgba(0,0,0,0.95)' }}
                                        contentStyle={{ width: "75%", borderRadius: '10px', overflow: 'scroll' }}
                                    >
                                        <div className='popup_height'>
                                            <div className='text-center' key="message">
                                                <TitleHeading text={'AajuBaaju My Clips'} />
                                                <div className="col-md-12 text-left">
                                                    <p>Submit a Clip link following the guidelines in <Link to='/help/clipsguide'>https://aajubaaju.in/help/clipsguide</Link> to moderation@aajubaaju.in</p>
                                                    <p><strong>Note:</strong> AajuBaaju My Clips qualification has only 3 attempts.</p>
                                                    <p><strong>Suggestion:</strong> Book a training session before applying for qualification.</p>
                                                </div>
                                                <ButtonStyle style={{ height: '40px' }} text={'Close'} className="btn" handleClick={this.applyClipPopup}></ButtonStyle>
                                            </div>
                                        </div>
                                    </Popup>
                                )}
                            </li>}
                        </ul>
                    </div>
                    <div className="col-md-4"></div>
                </div>
                <div className={`listings row ${styles.large_rect} ${styles.large_rect_margin}`}>
                    {(this.state.readOnly) ? null : <LargeRectangle spot={"-1"} />}
                </div>
                <div className={`${styles.billBoard} row`} style={{ backgroundColor: '#e5e5f2', paddingTop: '100px', paddingBottom: '100px' }}>
                    {(this.state.readOnly) ? null : <BillBoard spot={"-1"} />}
                </div>
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        is_login: state.is_login,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearPropsData: () => dispatch({ type: 'CLEAR_DATA' }),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Qualification);