import React from 'react'
import StarRatingComponent from 'react-star-rating-component';
import TextDecoration from '../../utility/TextDecoration';
import styles from '../forms/addpost.module.css'

export const Ratings = (props) => {
    return (
        <div className="listing_rating">
            <StarRatingComponent
                name="app6"
                starColor="#066b32"
                emptyStarColor="#066b32"
                value={parseInt(props.totalRatings)}
                editing={false}
                renderStarIcon={(index, value) => {
                    return (
                        <span>
                            <i className={index <= value ? 'fas fa-star' : 'far fa-star'} />
                        </span>
                    );
                }}
                renderStarIconHalf={() => {
                    return (
                        <span>
                            <span className={styles.span_rating}><i className="far fa-star" /></span>
                            <span><i className="fas fa-star-half" /></span>
                        </span>
                    );
                }} />

            <div className="listing_avarage_rating display_inline_block">{parseFloat(props.totalRatings).toFixed(1)}</div>
            <div className="listing_review_count display_inline_block">({TextDecoration.getReviewCount(props.totalReviews)})</div>
        </div>
    )
}