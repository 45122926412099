import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import referrals from '../../assets/referrals.jpg'

export const Promote = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <div className="col-md-12">
                        <h1 className={`${FooterStyle.pageHeading} text-center pb-5`}>How to do Paid Promotions in<br></br><span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h1>
                        <ul>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian can post Paid Listingsto avail additional features.</p>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian can also post Advertisements in the platform to promote their services or products.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>Types of Paid Listings in <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h2>
                                <table>
                                    <thead>
                                        <tr className="table-row extra_height">
                                            <th className={FooterStyle.half_head}>Advanced</th>
                                            <th className={FooterStyle.half_head}>Premium**</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Rs.1,500/-* per Year</td>
                                            <td className={FooterStyle.half_year}>Rs.3,000/-* per Year</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>1 Year Validity</td>
                                            <td className={FooterStyle.half_year}>1 Year Validity</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>No Similar Listings Section</td>
                                            <td className={FooterStyle.half_year}>No Similar Listings Section</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Can Buy Sponsored Section</td>
                                            <td className={FooterStyle.half_year}>Can Buy Sponsored Section</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Can Buy Featured Section</td>
                                            <td className={FooterStyle.half_year}>Can Buy Featured Section</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Banner</td>
                                            <td className={FooterStyle.half_year}>Banner</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Muliple Address</td>
                                            <td className={FooterStyle.half_year}>Muliple Address</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Working Hours</td>
                                            <td className={FooterStyle.half_year}>Working Hours</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Map</td>
                                            <td className={FooterStyle.half_year}>Map</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Social Media Sharing</td>
                                            <td className={FooterStyle.half_year}>Social Media Sharing</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>Rating & Comment</td>
                                            <td className={FooterStyle.half_year}>Rating & Comment</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>NA</td>
                                            <td className={FooterStyle.half_year}>Discussion</td>
                                        </tr>
                                        <tr className="table-row extra_height">
                                            <td className={FooterStyle.half_year}>NA</td>
                                            <td className={FooterStyle.half_year}>Text Formatting, Links & Images</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p className={`${FooterStyle.paragraph}`}><i>*+18% GST <br></br>**Available only for business listings.</i></p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>How to Get More Visibility?</h2>
                                <p className={`${FooterStyle.paragraph}`}>The visibility of the paid listings can be enhanced by purchasing clicks charged at Rs.5 per click. The listings will be displayed in the Home Page of the visitors or the Look Up Page when a visitor searches for a specific information.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Sponsored Section:</h2>
                                <p className={`${FooterStyle.paragraph}`}>The paid listings which are opted to display in the home page are called sponsored listings.The sponsored listings are charged on per click basis. The current price is Rs.5/- per click. A minimum of 1000 clicks should be purchased per order. The total cost of one sponsored listing order is Rs.5000 (+18% GST). The sponsored listing will be charged for 1 click every time it gets clicked in the home page.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Featured Section:</h2>
                                <p className={`${FooterStyle.paragraph}`}>The listings which are opted to display in the lookup page are called featured listings.The featured listings are charged on per click basis. The current price is Rs.5/- per click. A minimum of 1000 clicks should be purchased per order. The total cost of one featured listing order is Rs.5000 (+18% GST) The featured listing will be charged for 1 click every time it gets clicked in the lookup page featured section.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>How to Advertise in <span className={FooterStyle.logo_saffron}>aaju</span><span className={FooterStyle.logo_green}>baaju</span><span className={FooterStyle.logo_blue}>.in</span></h2>
                                <h2 className={`${FooterStyle.sectionHeading} pt-5`}>Advertisements:</h2>
                                <p className={`${FooterStyle.paragraph}`}>An AajuBaajuian can post an advertisement for own business and content or for other businesses.</p>
                                <p className={`${FooterStyle.paragraph}`}>The advertisements are charged on per view basis at a flat price without bidding. The current price is Rs.5/- per view.  Minimum views to be purchased per order are 1000. The total cost of one advertisement order is Rs.5000 (+18% GST). The advertisement will be charged for 1 view when a visitor views the advertisement for 30 seconds.</p>
                                <p className={`${FooterStyle.paragraph}`}>When an advertisement is viewed by a visitor, it will not be shown again to that visitor until next day. When an advertisement is not viewed by a visitor for 30 seconds, the view will not be charged.</p>
                                <p className={`${FooterStyle.paragraph}`}>The advertisements are posted as campaigns. There are 6 types of advertisements namely, Leaderboard, Banner, Large Rectangle, Billboard, Popup and Clip. An advertisement campaign can have any one of these advertisement types; one or more advertisement types; all advertisement types.</p>
                                <p className={`${FooterStyle.paragraph}`}>The view count is applicable for the campaign as a whole, i.e., views purchased is for the whole campaign and not for an individual advertisement type in the campaign.</p>
                                <p className={`${FooterStyle.paragraph}`}>Example: If 1000 view counts is purchased for an advertisement campaign which has a Leaderboard advertisement type and a Banner advertisement type.</p>
                                <p className={`${FooterStyle.paragraph}`}>The Leaderboard advertisement first gets displayed in a page and a visitor views it for 30 seconds. 1 view count will be deducted from 1000 view count purchased for the campaign. Now the view count balance is 999.</p>
                                <p className={`${FooterStyle.paragraph}`}>Next the Banner advertisement gets displayed in a page and a visitor views it for 30 seconds. Again 1 view count will be deducted from 999 view count balance of the campaign. Now the view count will be 998.</p>
                                <p className={`${FooterStyle.paragraph}`}>Now both the Leaderboard and the Banner advertisements gets displayed in a page and a visitor views both the advertisements for 30 seconds each. This time 2 view counts will be deducted from 998 view count balance of the campaign. Now the view count will be 996.</p>
                            </li>
                            <li>
                                <h2 className={`${FooterStyle.highlighter} pt-5`}>Empowering Tiny Businesses to Advertise Online.<br></br>
                                    No Complex Targeting Data Point.<br></br>
                                    No Price Bidding.<br></br>
                                    No Additional Expenses to Advertise Online.
                                </h2>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

