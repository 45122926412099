import React from 'react'
import { UploadAnimation } from '../../advertisements/styles/Upload_Animation/UploadAnimation'
import { AnimatedText } from '../../AnimatedText'

export const FileInput = (props) => {
    return (
        <div className="custom-file">
            <input id="thumbId" type="file" className="custom-file-input "
                aria-describedby="inputGroupFileAddon01" onChange={props.onFileSelect}
                accept={props.fileFormats ? props.fileFormats : "*"} disabled={props.disabled} />
            <label className="custom-file-label custom_upload_field image_label"
                htmlFor="inputGroupFile01">{(props.file_url === null) ? props.labelText : props.fileType === 'video'
                    ? <video autoPlay loop muted alt="Upload Video" className="img-fluid thumbnail_image" src={props.file_url} />
                    : <img alt="Uploaded Pic" className="img-fluid thumbnail_image" src={props.file_url} />}
                {props.inlineUploadAnimation && props.disabled ?
                    <UploadAnimation> <AnimatedText text={'uploading'} /> </UploadAnimation>
                    : (props.file !== null && props.file !== undefined) ? props.file.name :
                        (props.file_name) ? props.file_name : ''}
            </label>
        </div>
    )
}