import React from "react";
import {Helmet} from "react-helmet";
//import PropTypes from "react-grid-gallery";

const MetaDecor = ({ title, imageURL }) => {
    
    return(
        <Helmet>
            <title>{title}</title>
            <meta property="og:title" content="For Test"/>
            <meta property="og:image" content={imageURL} />
            <meta property="og:url" content={window.location.pathname + window.location.search} />
        </Helmet>
    );
};

export default MetaDecor;