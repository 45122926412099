import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import authservice from '../service/authservice';
import axios from 'axios';
import styles from '../authentication.module.css';
import { RedirectLink } from '../../UIElements/Redirect_link/Redirect_link';
import { useViewport } from '../../utility/useViewport';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';

export const Welcome = (props) => {

    window.scrollTo(0, 0); //while loading the component it will focus top
    const isMobile = useViewport();

    const [userDetails, setUserDetails] = useState({})
    if (authservice.getCurrentUser() !== null)
        props.history.push('/')

    const userId = useParams().userid;

    useEffect(() => {
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/basic-details?userId=' + userId).then(response => {
            setUserDetails(response.data)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    return (
        <div className="container-fluid" style={{ width: '100vw', height: '100vh' }}>
            <div className="row h-100 justify-content-center align-items-center m-1">
                <div className="col-12 col-md-8 text-center border rounded bg-white shadow p-4">
                    <h2>Congratulations, AajuBaajuian!</h2>
                    <h2>Your aajubaaju.in Account is Successfully Created.</h2>
                    <h1>
                        <Link to="/login/user">
                            <ButtonStyle type="button" className="btn btn-primary mt-4" text={'Proceed to Login'} />
                        </Link>
                    </h1>
                </div>
            </div>
        </div>
    )
}