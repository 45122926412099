import React from 'react'
import styles from './FormLabels.module.css'

export const FormLabels = (props) => {
    return (
        <label id={props.id} htmlFor={props.htmlFor} className={`${styles.addpost_label} ${props.className ? props.className : ''}`}
            style={props.style ? props.style : null}>
            {props.text ? props.text : props.children}
        </label>
    )
}