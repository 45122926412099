import React, { useEffect, useState, useRef } from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux'
import { EditorState, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

export const ClipContentMob = (props) => {

  const [listing] = useState(props.listing);
  const [editorState, setEditorState] = useState(null);
  const isAdmin = useSelector(state => state.isAdmin)
  const videoRef = useRef(null);
  const [showButton, setShowButton] = useState(false)
  let hideTimeout = null;

  const [videoLoaded, setVideoLoaded] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const clipUrlData = "Aajubaaju My Clips"
  const clipUrl = clipUrlData.replace(/[\s]/gm, '-')

  const handleVideoLoad = () => {
      setVideoLoaded(true);
   };

   useEffect(() => {
    if (videoLoaded) {
        setShowContent(true);
    }
  }, [videoLoaded]);

  const { element } = props;

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setShowButton(false);
        clearTimeout(hideTimeout);
        hideTimeout = setTimeout(() => setShowButton(true), 3000); // Show button after 3 seconds
      } else {
        video.pause();
        setShowButton(true);
        clearTimeout(hideTimeout);
      }
    }
  };

  useEffect(() => {
    if (listing.listingType === 'clips') {
      if (listing.myvideos_content) {
        const con = JSON.parse(listing.myvideos_content)
        const contentState = convertFromRaw(con);
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  }, [listing]);


  const clipshare = (event) => {
    event.preventDefault();
    const businessName = props.listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
    const shareUrl = `${window.location.origin}/shareclips/${businessName}/${props.listing.hpId}`;

    if (navigator.share) {
      navigator.share({
        title: 'Where to Share',
        url: [shareUrl]
      }).then(() => {
        console.log('Thanks for sharing!');
      }).catch(console.error);
    } else {
      const shareDialog = document.querySelector('.share-dialog');
      if (shareDialog) {
        shareDialog.classList.add('is-open');
      }
    }
  };


  if (!props.listing.hpId)
    return <> </>
  else {
    return (
        <div className='large_rect'>
          {props.listing.elements.map((element, index) => (
            <div key={element.value + index}>
              {element.type === 'video' ?
                <div key={index} className="video-container-mob">
                  {/* <div style={{ position: 'absolute', top: '18px', left: '18px' }}>
                    <Link to='/' style={{ position: 'absolute' }}><i style={{ fontSize: '45px' }} className="fa fa-home home_link styled-icon"></i></Link>
                  </div> */}
                  <video
                    className='clip_video'
                    ref={videoRef}
                    src={process.env.REACT_APP_IMAGES_URL + element.value}
                    loop
                    autoPlay
                    id="myVideoMob"
                    onLoadedData={handleVideoLoad}
                    onClick={handleVideoClick}
                  ></video>
                  {/* {videoLoaded &&(<> */}
                    {/* Only show the rest of the content after the video has loaded */}
            {showContent && (
                <>
                    <div style={{ position: 'absolute', top: '18px', left: '18px' }}>
                        <Link to='/' style={{ position: 'absolute' }}>
                            <i style={{ fontSize: '45px' }} className="fa fa-home home_link styled-icon"></i>
                        </Link>
                    </div>

                 {showButton &&
                  <button
                    onClick={handleVideoClick}
                    className='play-pause-button'
                  >
                    <i className="fa fa-pause styled-icon" style={{ fontSize: '24px', color: 'white' }}></i>
                  </button>
                }
                  {isAdmin && listing.myvideos_content !== '' && listing.listingType === 'clips' ?
                    <div className="rdw-storybook-root">
                      <Editor
                        editorState={editorState}
                        wrapperClassName="rdw-storybook-wrapper"
                        readOnly
                        toolbarHidden
                      />
                    </div> : null}
                  <div className="button-container">
                    <Link className="clip-button-mob" type="button" title="Share this article" onClick={clipshare}>
                      <i className='fa fa-paper-plane styled-icon '></i>
                    </Link>
                    {props.listing.subCategories.map((subcategory) => (
                      <Link
                        key={subcategory.id}
                        to={`/content/${clipUrl}/${subcategory.subCategory.replace(/[\s]/gm, '-')}/clips`}
                        className="watch-button-mob"
                        title="{`Watch all`}"
                      >
                        <i className='fa fa-eye styled-icon '></i>
                      </Link>
                    ))}
                  </div>

                  </>)}
                </div>
                
                : null}
            </div>
          ))}
        </div>
    )
  }
}

export default (ClipContentMob); 
