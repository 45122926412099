import React from 'react'
import styles from './package-selector.module.css'
import { PackageSelector } from './PackageSelector'

export const PackageSelectors = (props) => {
    return (
        <div className={`${styles.select_package} form-row`}>
            <PackageSelector
                disabled={props.hpId}
                title={'Basic'}
                value={'basic'}
                package={props.package}
                selectedListingType={props.selectedListingType}
                setPackage={props.handleChange}
            />
            <PackageSelector
                disabled={props.hpId}
                title={'Advanced'} value={'advanced'}
                package={props.package}
                selectedListingType={props.selectedListingType}
                setPackage={props.handleChange}
                packageCost={1500}
                noCompetitorAds noSimilarListing
                sponsored featured
                banner multipleAddress
                workingHours map socialMediaShare reviews
            />
            {(props.selectedListingType !== '' && props.selectedListingType.value === 'business') &&
                <PackageSelector
                    disabled={props.hpId}
                    title={'Premium'}
                    value={'premium'}
                    package={props.package}
                    selectedListingType={props.selectedListingType}
                    setPackage={props.handleChange}
                    packageCost={3000}
                    noCompetitorAds noSimilarListing
                    sponsored featured slider multipleAddress
                    workingHours map socialMediaShare
                    reviews discussion media gallery
                />}
        </div>
    )
}