import React, { Component } from 'react';
import Popup from 'reactjs-popup';
import { ButtonStyle } from './UIElements/Buttons/ButtonStyle';
import { TitleHeading } from './UIElements/headings/TitleHeading';

function ConfirmPopup(props) {
    return (
        <div>
            <Popup
                open={true}
                closeOnDocumentClick={true}
                closeOnEscape={true}
                onOpen={null}
                onClose={() => props.cancel(false)}
                lockScroll={false}
                contentStyle={{ width: "500px" }}
            >
                <div className="confirm_popup">
                    <div className="confirm_message">
                        {(props.data.name) ?
                            <TitleHeading className={'popup_title'} text={`Confirm ${props.data.name}`} /> : null}
                        {(props.data.remark ?
                            <div>
                                <div className="form-row col-md-12">
                                    <h4 className="col-md-6">Remark</h4>
                                    <h4 className="text-right col-md-6">Date : {props.data.date}</h4>
                                </div>
                                <div className="form-row col-md-12">
                                    <p className="col-md remark-overview"> {props.data.remark} </p>
                                </div>
                            </div> : null)}
                    </div>
                    <div className="form-row">
                        <div className="col-md-11 text-right">
                            <ButtonStyle type="button"
                                className="btn address_margin"
                                handleClick={() => props.confirm(props.data)} >Confirm</ButtonStyle>

                            <ButtonStyle type="button"
                                className="btn address_margin"
                                handleClick={() => props.cancel()} >Cancel</ButtonStyle>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    )
}

export default ConfirmPopup;