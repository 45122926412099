import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Avatar from '../header/Avatar';
import authservice from '../Authentication/service/authservice';
import styles from './admin.module.css'
import ablogo from '../../assets/ablogo.svg'

class AdminHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            username: authservice.getCurrentUserName(),
            is_login: false,
        }
    }

    static getDerivedStateFromProps(props, state) {

        let update = {};
        if (props.is_login !== state.is_login) {
            //console.log(update.is_login);
            update.is_login = props.is_login;
            //console.log(update.username);
            update.username = authservice.getCurrentUserName()

        }
        else if (authservice.getCurrentUserName() !== state.username) {
            //console.log(update.username);
            update.username = authservice.getCurrentUserName()
        }
        return update;
    }

    logout = () => {
        authservice.logOut();
        this.props.isLogin(false)
        this.props.isAdmin(false)
        window.location = "/"
    }

    render() {
        return (
            <div className={`header ${styles.navbar_sticky}`} style={{ borderBottom: "1px solid #000080", zIndex: '3' }}>
                <div className={styles.middle_strip}>
                    <div className={`grid_system ${styles.grid_container}`}>
                        <div className="row">
                            <div className="col-md-2 logo_box low_padding" style={{ paddingTop: '8px' }}>
                                <Link to="#"><img className="img-fluid" src={ablogo} alt="Logo" /></Link>
                            </div>

                            <div className="col-md-8"></div>

                            <div className="col-md-2 low_padding" style={{ paddingTop: "1rem" }}>
                                <div className="post_help row">
                                    <div className={`list-inline ${styles.right_side_btn} col-md-2 avatar`}>
                                        {(this.state.username !== null) ? <Avatar /> : null}
                                    </div>

                                    <ul className={`list-inline ${styles.right_side_btn} col-md-10`}>
                                        {(this.state.username !== null) ? <li className={`list-inline-item ${styles.right_list}`}><button onClick={this.logout} className={`btn btn-link ${styles.header_link} social-icon text-xs-center`}>Logout</button></li> : <li className="list-inline-item"><Link to="/login/user" className="social-icon text-xs-center" >Login</Link></li>}
                                        <li className={`list-inline-item ${styles.right_list_help}`}><Link to="#" className={`btn btn-link ${styles.header_link_help} social-icon text-xs-center`} >Help</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    //console.log(state)
    return {
        is_login: state.is_login,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        isLogin: (is_login) => dispatch({ type: 'IS_LOGIN', is_login: is_login }),
        isAdmin: (is_admin) => dispatch({ type: 'ADMIN_LOGIN', isAdmin: is_admin }),
        clearPropsData: () => dispatch({ type: 'CLEAR_DATA' })
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminHeader));
