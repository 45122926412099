import React, { Component } from 'react';
import { ContentTabs } from './ContentTabs';
import { VideoCard } from './VideoCard';
import RecentListings from './RecentListings';
import styles from './cover.module.css';
import { withRouter } from 'react-router-dom';
import VideoListingMore from './VideoListingMore';
import ClipListingMore from './ClipListingMore';
import PageListingMore from './PageListingMore';
import PhotoListingMore from './PhotoListingMore';
import AlbumListingMore from './AlbumListingMore';
import ReviewListingMore from './ReviewListingMore';

class ContentData extends Component {
    constructor(props) {
        super(props);
        const { match } = this.props;
        const initialTab = match.params.tab;
        this.state = {
            listing_type: initialTab,
        }
        this.tabs = this.tabs.bind(this)
    }

    componentDidUpdate(prevProps) {
        const { match } = this.props;
        if (prevProps.match.params.tab !== match.params.tab) {
            this.setState({ listing_type: match.params.tab });
        }
    }

    componentDidMount() {
        const { match } = this.props;
        window.scrollTo(0, 0);
        this.setState({ listing_type: match.params.tab });
    }


    tabs = (id) => {
        if (!id || this.state.listing_type === id) return;

        this.setState({ listing_type: id });
        this.props.history.replace(`/contents/${this.props.match.params.username}/${id}`);
    };

    render() {
        return (
            <>
                <ContentTabs onToggle={this.tabs} listingType={this.state.listing_type} {...this.props} />
                <div className={`container-fluid ${styles.backgroundStyle}`}>

                    <div className="row">
                        <div className="col-12">
                            {this.state.listing_type === 'all' ?
                                <>
                                    <div className="col-12">
                                        <VideoCard {...this.props} listingType={'article'} setContentStatus={this.setContentStatus} />
                                    </div>
                                    <div className="col-12">
                                        <VideoCard {...this.props} listingType={'clips'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'photos'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'album'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'news'} />
                                    </div>
                                    <div className="col-12">
                                        <RecentListings {...this.props} listingType={'reviews'} />
                                    </div>
                                </>
                                : null}
                            <div className="col-12">
                                {this.state.listing_type === 'videos' ?
                                    <VideoListingMore {...this.props} listingType={'article'} />
                                    : null}
                                {this.state.listing_type === 'clips' ?
                                    <ClipListingMore {...this.props} listingType={'clips'} />
                                    : null}
                                {this.state.listing_type === 'pages' ?
                                    <PageListingMore {...this.props} listingType={'news'} />
                                    : null}
                                {this.state.listing_type === 'photos' ?
                                    <PhotoListingMore {...this.props} listingType={'photos'} />
                                    : null}
                                {this.state.listing_type === 'album' ?
                                    <AlbumListingMore {...this.props} listingType={'album'} />
                                    : null}
                                {this.state.listing_type === 'reviews' ?
                                    <ReviewListingMore {...this.props} listingType={'reviews'} />
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default (ContentData);
