import React, { Component } from 'react';
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import './styles/avatar.css';
import authservice from '../Authentication/service/authservice';

class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdown: false,
            profilePhoto: '',
            isProfileUpdated: false,
            isAdmin: this.props.isAdmin,
        }
        this._isMounted = false;
    }

    componentDidMount() {
        this._isMounted = true;
        this.getProfilePhoto();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        let update = {};
        if (props.isProfileUpdated !== state.isProfileUpdated) {
            update.isProfileUpdated = props.isProfileUpdated;
        }
        if (props.isAdmin !== state.isAdmin) {
            update.isAdmin = props.isAdmin;
        }
        return update;
    }

    componentDidUpdate(props, state) {
        if (props.isProfileUpdated !== this.state.isProfileUpdated) {
            this.props.setProfileUpdated(false);
            this._isMounted && this.setState({ isProfileUpdated: false })
            this.getProfilePhoto();
        }
    }
    getProfilePhoto() {
        const username = authservice.getCurrentUserName();
        //var image = new FormData();
        Axios.get(process.env.REACT_APP_BACKEND_URL + `/abmgr/user/get-profile-photo/${username}`)
            .then(response => {
                this._isMounted &&
                    this.setState({ profilePhoto: response.data })
            }).catch(error => {
                console.warn(error);
            })
    }


    handleClick = (e) => {
        e.preventDefault();
        if (this.state.isAdmin) {
            this.props.history.push('/admin-panel/manage-profile')
        } else {
            try {
                if (this.icon && this.icon.contains(e.target)) {
                    const toggle = !this.state.dropdown
                    this.setState({
                        dropdown: toggle
                    })
                    // if (toggle)
                    //     document.addEventListener("click", this.handleClick, false);
                    // else
                    //     document.removeEventListener("click", this.handleClick, false);
                } else {
                    this.setState({ dropdown: false })
                    document.removeEventListener("click", this.handleClick, false);
                }
            } catch (error) {
                this.setState({ dropdown: false })
                document.removeEventListener("click", this.handleClick, false);
            }
        }
    }

    render() {
        return (
            <div ref={icon => this.icon = icon}>
                {this.state.profilePhoto ?
                    <span className="">
                        <img src={process.env.REACT_APP_IMAGES_URL + this.state.profilePhoto} height="40" width="40" className="header-avatar" aria-hidden="true" onClick={this.handleClick} />
                    </span> : <i className="fa fa-user-circle-o fa-3x cursor" aria-hidden="true" onClick={this.handleClick} />}

                <div>{this.state.dropdown && (
                    <div className='menu-lists'>
                        <div className='arrow'></div>
                        <div className='inner-box'>
                            <ul>
                                <Link to="/manageprofile"><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>Manage Profile</button></Link>
                                <Link to="/manageposts"><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>Manage Posts</button></Link>
                                <Link to="/manageadvertisement"><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>Manage Ads</button> </Link>
                                <Link to="/viewreport"><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>View Report</button></Link>
                                <Link to={`/categories/${authservice.getCurrentUserName()}`}><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>My Contents</button></Link>
                                <Link to="/qualification"><button className="btn avatar-dropdown-btn text-xs-center" onClick={() => this.setState({ dropdown: false })}>Qualification</button></Link>
                            </ul>
                        </div>
                    </div>
                )}</div>
            </div>
            
        );
    }
}

const mapStateToProps = state => {
    return {
        isProfileUpdated: state.isProfileUpdated,
        isAdmin: state.isAdmin,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        setProfileUpdated: (isProfileUpdated) => dispatch({ type: 'IS_PROFILE_UPDATED', isProfileUpdated: isProfileUpdated }),
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Avatar));