import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { ButtonStyle } from '../UIElements/Buttons/ButtonStyle';

export const PhotosGuidlines = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <section className="col-md-12">
                        <ol type="I" className={FooterStyle.margin}>
                            <h4 className={`${FooterStyle.paraHeading} pt-3`}>
                                {/* style={{ 'margin-top': '40px' }}> */}
                                General Guidelines:</h4>
                            <ol type="1">
                                <li><p className={`${FooterStyle.paragraph}`}>AajuBaaju My photos section is not a personal photo album.</p></li>
                                <li>
                                    <p className={`${FooterStyle.paragraph}`}>AajuBaajuian presence is mandatory in a minimum of 10 photos.</p>
                                </li>
                                <li>
                                    <p className={`${FooterStyle.paragraph}`}>AajuBaajuian should not be the main focus in any of the photos.</p>
                                </li>
                                <li>
                                    <p className={`${FooterStyle.paragraph}`}>All photos should be in a sequence and in the same dress.</p>
                                </li>
                            </ol>
                            <h4 className={`${FooterStyle.paraHeading} pt-5`}>Niche Level Guidelines for AajuBaaju My Photos:</h4>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Tour Photos:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Photo story description should contain details of the trip, date and place.</p></li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>AajuBaajuian should not hide any details or feature of the information, the photo should convey.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>There should be a tagline for every photo to explain the story of the photo.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Group photos and self posing photos will be allowed only if there are more than 10 photos meeting the above guidelines.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Multiple photo stories for different days of a single vaction/trip should not be created if the number of photos in the first listing is less than 50.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Event Photos:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Photo story description should contain details of the event, date and place.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>The photos should be in a sequence with the tagline specifying the activity in the event.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>In case of group photos, AajuBaajuian should be prominently visible.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>Product Photos:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>A business listing has to be added before adding a listing for product photos.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Photo story description should have the business listing link and a brief about the photos.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>All photos should be taken by the AajuBaajuian.</p>
                                    </li>
                                </ol>
                            </li>
                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>TEMPLE PHOTOS:</h4>
                                <ol type="1">

                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>The photo story description should have details about the historical significance or sthala purana of the temple.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Should have the address and temple timings.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Tagline should describe the location and not the activity of the AajuBaajuian.</p>
                                    </li>
                                    <li>
                                        <p className={`${FooterStyle.paragraph}`}>Minimum 10 photos should be inside the temple.  Way to temple, surroundings of the temple photos can be added only when there are more than 10 photos.</p>
                                    </li>
                                </ol>
                            </li>

                            <li>
                                <h4 className={`${FooterStyle.paraHeading} pt-5`}>IMPORTANT NOTE:</h4>
                                <p className={`${FooterStyle.paragraph}`}>We will be adding/updating the guidelines as and when we come across a need, based on the user behaviour. It is every AajuBaajuian's reponsibility to check the niche guidelines every time before they create a Photo Story.</p>
                            </li>
                        </ol>
                        <div className='row pb-1'>
                            {/* style={{ paddingBottom: '5px' }}> */}
                            <div className='col-md-9'></div>
                            <div className="col-md-3 add_button animated_back_button">
                                <ButtonStyle type="button" className="form-control btn btn-block btn_font"
                                    handleClick={() => props.history.goBack()}>&nbsp; &laquo; &nbsp;Go Back </ButtonStyle>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}