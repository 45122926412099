import React from 'react';
import { BasicVideoHomeCard } from './BasicVideoHomeCard';

export const BasicCardsVideo = (props) => {
    return (
        props.listings.map(listing => {
            return (
                <BasicVideoHomeCard
                    {...props}
                    key={listing.hpId}
                    listing={listing}
                    isViewed={props.viewedContentIds.includes(listing.hpId)}
                />
            )
        })
    )
}