class ABeCPages {
    getPageName(path) {
        switch (path) {
            case '/':
            case '/processing/:id':
                return "HomePage"

            case '/contact':
                return "ContactPage"

            case '/addpost':
            case '/advertise':
            case '/listing-basic':
            case '/listing-advanced':
            case '/listing-premium':
            case '/updateadvertisement/:id':
            case '/update-post/:id':
            case '/update-post/content/premium/:id':
            case '/update-post/content/advanced/:id':
                return "FormsPage"

            case '/contents/:username/:tab?':
            case '/categories/:username':
            case '/content/:type/:subcategory/:tab':
                return "MyContent"

            case '/help':
            case '/helpPage':
            case '/help/registration':
            case '/help/mob_confirm':
            case '/help/email_confirm':
            case '/help/profile_setup':
            case '/help/addpost':
            case "/help/forget_pass":
                return "HelpPage"

            case '/l/:b/:hpId':
            case '/single-listing/:hpId/:addressId':
                return "ListingPage"

            case '/clips/:b/:hpId':
            case '/shareclips/:b/:hpId':
                return "ClipsPage"

            // case '/shareclips/:b/:hpId':
            //     return "ShareClipPage"

            case '/login/:required':
            case '/forgotpassword':
            case '/activation-success/:username':
            case '/login-otp/:verify':
                return "LoginPage"

            case '/registration':
            case '/otp/:verify':
            case '/resendemail/:id':
            case '/expiryemail/:token':
                return "RegistrationPage"

            case '/search':
                // case '/MyClips/:subcategory':
                return "LookupPage"

            case '/payment':
            case '/order/:id':
                return "PaymentPage"

            case '/manageprofile':
            case '/manageposts':
            case '/manageadvertisement':
            case '/viewreport':
            case '/view-downline':
            case '/listingperformance':
            case '/advtperformance':
            case '/ad-order-history/:id':
            case '/post-order-history/:id':
            case '/subscription-orders/:id':
            case '/qualification':
                // case '/MyPages/:subcategory':
                // case '/MyVideos/:subcategory':
                // case '/MyPhotos/:subcategory':
                // case '/MyReviews/:subcategory':
                return "UserManagementPage"

            case '/terms-of-service':
            case '/privacy-policy':
            case '/cookies-policy':
            case '/advertisement-policy':
            case '/success-story':
            case '/success-story/archieve':
            case '/content-policy':
            case '/refund-policy':
            case '/content-policy/aajubaaju-my-photos':
            case '/content-policy/aajubaaju-my-videos':
            case '/content-policy/aajubaaju-my-reviews':
            case '/content-policy/event':
            case '/content-policy/job':
            case '/content-policy/business':
            case '/content-policy/aajubaaju-my-pages':
            case '/about-us':
            case '/knowledge-base':
            case '/advertisements':
            case '/posts':
            case '/referring':
            case '/how-to-earn':
            case '/promote':
            case '/we-are-at':
                return "FooterLinkPages"

            default:
                return "";
        }
    }
}

export default new ABeCPages();