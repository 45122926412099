import React from 'react'
import styles from './link_button.module.css'



export const LinkButton = (props) => {
    return (
      <button id={props.id} onClick={props.handleClick} className={`btn btn-link ${styles.header_link} text-xs-center ${props.className ? props.className : ''}`}
      style={props.style ? props.style : null}>
        {props.text ? props.text : props.children}
      </button>
    );
   }