import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { TitleHeading } from '../UIElements/headings/TitleHeading';
import listingService from '../listings/service/listingService';
import moment from 'moment';
import styles from './cover.module.css';
import { ClipCardRow } from './ClipCardRow';

const ClipListingMore = (props) => {
    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState({});
    const [myClips, setMyClips] = useState([]);
    const [offsets, setOffsets] = useState({});
    const viewedListings = useSelector(state => state.viewedListings);

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/shareclips/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: null,
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        const fetchInitialVideos = async () => {
            try {
                const username = props.match.params.username;
                const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hpmgr/listing/getall-clips-subcategory?userName=${username}`);
                const clips = response.data.map(item => item.subCategory);
                setMyClips(clips);
                const initialOffsets = clips.reduce((acc, subCategory) => ({ ...acc, [subCategory]: 0 }), {});
                setOffsets(initialOffsets);

                const initialListings = {};

                for (const subCategory of clips) {
                    const data = await listingService.getAllSubCatClipsContent(coords, props.listingType, [subCategory], 0);
                    initialListings[subCategory] = data.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)));
                }

                setListings(initialListings);
            } catch (error) {
                console.error("Error fetching clips:", error);
            }
        };

        fetchInitialVideos();
    }, [props.match.params.username]);

    const handleSeeMore = async (subCategory) => {
        try {
            const newOffset = offsets[subCategory] + 1;
            const data = await listingService.getAllSubCatClipsContent(coords, props.listingType, [subCategory], newOffset);
            setListings((prevListings) => ({
                ...prevListings,
                [subCategory]: (prevListings[subCategory] || []).concat(data).sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate))),
            }));
            setOffsets((prevOffsets) => ({ ...prevOffsets, [subCategory]: newOffset }));
        } catch (error) {
            console.error("Error fetching more clips:", error);
        }
    };

    return (
        Object.keys(listings).length > 0 ? (
            <div>
                <TitleHeading text="My Clips" />
                {myClips.map((subCategory, index) => (
                    <div key={index} className="mb-0">
                        <h2 className={`p-2 ${styles.subCatName}`}>{subCategory}</h2>
                        {listings[subCategory] && listings[subCategory].length > 0 ? (
                            <>
                                <ClipCardRow
                                    listings={listings[subCategory]}
                                    showContent={getListingDetails}
                                    viewedContentIds={viewedListings}
                                    type={props.listingType}
                                />
                                <button
                                    className={`btn btn-primary ${styles.read_more_button}`}
                                    onClick={() => handleSeeMore(subCategory)}
                                >
                                    See More...
                                </button>
                                <br />
                                <br />
                            </>
                        ) : (
                            <p>No clips available for this subcategory.</p>
                        )}
                    </div>
                ))}
            </div>
        ) : <></>
    );
}

export default ClipListingMore;
