import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BillBoard from '../../advertisements/BillBoard';
import LargeRectangle from '../../advertisements/LargeRectangle';
import { TitleHeading } from '../../UIElements/headings/TitleHeading';
import { CardsColumn } from '../cards/CardsColumn';
import listingService from '../service/listingService';
import { useViewportBillBoard } from '../../utility/useViewport';

export const SponsoredNewsArticle = (props) => {

    const coords = useSelector(state => state.current_coords);
    const [listings, setListings] = useState([]);
    const viewedListings = useSelector(state => state.viewedListings);
    const isBillboard = useViewportBillBoard();

    const heading = props.listingType === 'news' ? 'AajuBaaju My Pages' : props.listingType === 'reviews' ? 'AajuBaaju My Reviews' : props.listingType === 'photos' ? 'AajuBaaju My Photos' : props.listingType === 'album' ? 'AajuBaaju My Album' : 'AajuBaaju My Videos'

    const getListingDetails = (listing) => {
        const searchCriteria = listingService.getListingSettings(listing, coords);
        const businessName = listing.businessName.replace(/[^\w\s^&]/gm, ' ').trim().replace(/[\s]/gm, '-')
        const listingPath = encodeURI(`/l/${businessName}/${listing.hpId}`);
        props.history.push(listingPath, {
            place: 'sponsored',
            isViewed: viewedListings.includes(listing.hpId),
            searchCriteria: searchCriteria,
        });
    }

    useEffect(() => {
        // if (!coords.latitude)
        //     return;

        listingService.getAllListingsByType(coords, props.listingType).then(data => {
            setListings(data.sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate))));
        })
    }, [coords])

    return (
        listings.length > 0 ?
            <div>
                <div className='pt-lg-4'><TitleHeading text={`Sponsored ${heading} Listings`} /></div>
                <CardsColumn listings={listings}
                    showContent={getListingDetails}
                    viewedContentIds={viewedListings}
                    type={props.listingType}
                    section='sponsored' />
                <div className='row' style={{backgroundColor:'#e5e5f2', paddingTop: '100px', paddingBottom: '100px'}}>
                    {isBillboard ? <LargeRectangle spot={props.bannerAdSpot} />
                        : <BillBoard spot={props.bannerAdSpot} />}
                </div>
            </div>
            : <></>
    )
}