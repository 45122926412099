import React from "react";

export const AnimatedText = (props) => {
    switch (props.text) {
        case 'uploading':
            return (
                <div style={props.style} key={props.key}>
                    <div className="load-6">
                        <div>
                            <div className="l-1 letter">U</div>
                            <div className="l-2 letter">p</div>
                            <div className="l-3 letter">l</div>
                            <div className="l-4 letter">o</div>
                            <div className="l-5 letter">a</div>
                            <div className="l-6 letter">d</div>
                            <div className="l-7 letter">i</div>
                            <div className="l-8 letter">n</div>
                            <div className="l-9 letter">g</div>
                            <div className="l-10 letter">.</div>
                            <div className="l-11 letter">.</div>
                            <div className="l-12 letter">.</div>
                        </div>
                    </div>
                </div>
            )
        case 'loading':
            return (
                <div style={props.style} key={props.key}>
                    <div className="load-6">
                        <div>
                            <div className="l-1 letter">L</div>
                            <div className="l-2 letter">o</div>
                            <div className="l-3 letter">a</div>
                            <div className="l-4 letter">d</div>
                            <div className="l-5 letter">i</div>
                            <div className="l-6 letter">n</div>
                            <div className="l-7 letter">g</div>
                            <div className="l-8 letter">.</div>
                            <div className="l-9 letter">.</div>
                            <div className="l-10 letter">.</div>
                        </div>
                    </div>
                </div>
            )
        case '3_bars':
            return (
                <div style={props.style} key={props.key}>
                    <div className="load-6">
                        <div>
                            <div className="l-1 letter">I</div>
                            <div className="l-2 letter">&nbsp;</div>
                            <div className="l-2 letter">I</div>
                            <div className="l-3 letter">&nbsp;</div>
                            <div className="l-3 letter">I</div>
                        </div>
                    </div>
                </div>
            )
        default:
            break;
    }
}