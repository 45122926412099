import React from 'react';

export const useViewport = () => {
    const breakPoint = 720;
    const breakPoint_lb = 1280;
    const [isMobile, setMobile] = React.useState(window.innerWidth < breakPoint)
    const [isTablet, setTablet] = React.useState(window.innerWidth < breakPoint_lb)

    React.useEffect(() => {
        const handleWindowResize = () => setMobile(window.innerWidth < breakPoint);
        const handleWindowResize_lb = () => setTablet(window.innerWidth < breakPoint_lb);
        window.addEventListener("resize", handleWindowResize, handleWindowResize_lb);
        return () => window.removeEventListener("resize", handleWindowResize, handleWindowResize_lb);
    }, []);
    return isMobile, isTablet;
}

export const useViewportBanner = () => {
    const bannerBreakPoint = 568;
    const [isBanner, setBanner] = React.useState(window.innerWidth < bannerBreakPoint)

    React.useEffect(() => {
        const handleWindowResize_banner = () => setBanner(window.innerWidth < bannerBreakPoint);
        window.addEventListener("resize", handleWindowResize_banner);
        return () => window.removeEventListener("resize", handleWindowResize_banner);
    }, []);
    return isBanner;
}

export const useViewportBillBoard = () => {
    const billboardBreakPoint = 1000;
    const [isBillboard, setBillboard] = React.useState(window.innerWidth < billboardBreakPoint)

    React.useEffect(() => {
        const handleWindowResize_bill = () => setBillboard(window.innerWidth < billboardBreakPoint);
        window.addEventListener("resize", handleWindowResize_bill);
        return () => window.removeEventListener("resize", handleWindowResize_bill);
    }, []);
    return isBillboard;
}

export const useViewportLookupCard = () => {
    const lookupBreakPoint = 1326;
    const [isLookupCard, setLookupCard] = React.useState(window.innerWidth < lookupBreakPoint)

    React.useEffect(() => {
        const handleWindowResize_lookup = () => setLookupCard(window.innerWidth < lookupBreakPoint);
        window.addEventListener("resize", handleWindowResize_lookup);
        return () => window.removeEventListener("resize", handleWindowResize_lookup);
    }, []);
    return isLookupCard;
}
