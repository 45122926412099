import React, { Component } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'
import { Link } from 'react-router-dom';

class SuccessStory extends Component {

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <>
                <div className={`${FooterStyle.grid_container} terms_of_service`}>
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className={`${FooterStyle.pageHeading} text-center pb-3`}><span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span> with a Mission <br></br>in Sync with the Vision </h1>
                            <h2 className={`${FooterStyle.sectionHeading} pt-5`}><span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span> who have started to withdraw their loyalty earnings.</h2>
                            <div class="container">
                                <div class="row">
                                    <div className="col-6 col-md-4 col-lg-2 mt-4">
                                        <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/manishpadinesh.jpg"} />
                                    </div>
                                    <div className="col-6 col-md-4 col-lg-2 mt-4">
                                        <img className='img-fluid loyalty' style={{ width: '100%', height: 'auto', aspectRatio: '1/1' }} src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/UsernameManisha.jpg"} />
                                    </div>

                                    {/* <img className='img-fluid' src={process.env.REACT_APP_IMAGES_URL + "assets/AboutUs/Kalpana.jpg"} /> */}
                                </div>
                            </div>
                            <h1 className={`${FooterStyle.highlighter} pt-5`}>Here are some of the Success Stories shared by some of the <span style={{ color: '#FF9933' }}>Aaju</span><span style={{ color: '#138808' }}>Baaju</span><span style={{ color: '#000080' }}>ians</span>.</h1><br></br><br></br>
                            <ul>
                                <h4 className={`${FooterStyle.paraHeading}`}>First Loyalty Earning Withdrawal Success Story:</h4><br></br>
                                <li className='text-center'>
                                    <div className="image-container text-center" style={{ display: 'inline-block', position: 'relative' }}>
                                        <a href="https://www.aajubaaju.in/l/First-Loyalty-of-25-000-and-Different-Ways-of-Earning/8539">
                                            <img className="img-fluid" width='650px' height='auto' src={"https://files.aajubaaju.in/aajubaaju-hquhqcdpf7-video-size-1200px-x-630px.jpg"} />
                                            <div className={FooterStyle.overlay}>
                                                <i className={`fa fa-play-circle-o ${FooterStyle.playIcon}`}></i>
                                            </div>
                                        </a>
                                    </div>
                                </li>
                            </ul><br></br>
                            <p className={`${FooterStyle.paragraph}`}>
                                Click here to see <i className={`fas fa-hand-point-right ${FooterStyle.linkPoint}`}></i><Link to="/success-story/archieve" className={FooterStyle.link}> Archived Stories</Link>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="listings_footer">
                    <div className={`${styles.large_rect} row`}>
                        <LargeRectangle spot={"-1"} />
                    </div>
                    <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                        <BillBoard spot={"-1"} />
                    </div>
                </div>
            </>
        )
    }
}
export default SuccessStory;