import React from 'react'
import { VCard } from './VCard'

export const CardsColumn = (props) => {
    return (
        <div className="row items_list pt-4 pb-5">
            {props.listings.map(listing => {
                return <VCard
                        {...props}
                        key={listing.hpId}
                        listing={listing}
                        isViewed={props.viewedContentIds.includes(listing.hpId)}
                    />
            })}
        </div>
    )
}