import { store } from 'react-notifications-component';

export const notifyUser = (title, message, type, container) => {
    //success, danger, info, default, warning
    store.addNotification({
        title: title,
        message: message,
        type: type,
        insert: "top",
        container: container ? container : "top-left",
        animationIn: ["animate__animated", "animate__fadeIn"], //needs animate.css for animation to work
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true,
            pauseOnHover: true,
            click: true,
        },
    });
}

export const type = { SUCCESS: 'success', DANGER: 'danger', INFO: 'info', DEFAULT: 'default', WARNING: 'warning' }

export const container = {
    TOP_LEFT: 'top-left',
    TOP_RIGHT: 'top-right',
    CENTER: 'center',
    BOTTOM_LEFT: 'bottom-left',
    BOTTOM_RIGHT: 'bottom-right',
    BOTTOM_CENTER: 'bottom-center',
}
