import React, { useCallback, useState } from 'react'
import axios from 'axios'
import { container, notifyUser, type } from '../../utility/notifyUser';
import { useLocation } from 'react-router-dom';
import styles from '../authentication.module.css';
import { validEmailRegex } from '../../RegxConst';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import { useViewport } from '../../utility/useViewport';
import fullEmployment from '../../../assets/fullEmployment.jpg'
import ablogo from '../../../assets/ablogo.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const ResendEmail = (props) => {

    const [sendingMail, setSendingMail] = useState(false);
    const [newEmailId, setNewEmail] = useState('');
    const [error, setError] = useState('');
    const [enableUpdateEmail, setEnableUpdateEmail] = useState(false);
    const userId = props.match.params.id
    const query = useLocation().search;
    const q = query.split("=")
    const isMobile = useViewport()

    let email = ''
    if (q[0].includes('auth_mod'))
        email = q[1];

    const resendEmail = () => {
        setSendingMail(true)
        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/resend-verification-mail/' + userId)
            .then(response => {
                setSendingMail(false)
                if (response.data)
                    notifyUser('', 'A Verification link has been sent to your registered email address', type.INFO, container.BOTTOM_RIGHT);
            }).catch(error => {
                setSendingMail(false)
                if (error.response)
                    notifyUser('', error.response.data, type.DANGER, container.BOTTOM_RIGHT);
            })
    }

    const editEmailId = useCallback(() => {
        setError('')
        setEnableUpdateEmail((enableUpdateEmail) => !enableUpdateEmail)
        setNewEmail('')
    }, [])

    const updateEmail = useCallback((e) => {
        e.preventDefault();
        const error = validEmailRegex.test(newEmailId)
            ? ''
            : 'Email is not valid!';
        setError(error)
        if (error)
            return

        const data = {
            userId: userId,
            mobileNumber: null,
            email: newEmailId,
        }
        axios.post(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/update-auth-info', data).then(response => {
            if (response.data) {
                notifyUser("Update Email", response.data, type.INFO, container.BOTTOM_RIGHT)
                props.history.push({
                    pathname: `/resendemail/${userId}`,
                    search: `?auth_mod=${newEmailId}`
                })
                editEmailId();
            }
        }).catch(error => {
            if (error.response)
                notifyUser("Update Email", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
        })
    }, [newEmailId])

    return (
        <div className="container-fluid" style={{ padding: '2%' }}>
            <div className="row">
                <div className="col-12 col-lg-9 text-left">
                    <img
                        src={fullEmployment}
                        alt="Advertisement Space"
                        className="img-fluid"
                        style={{ width: '100%', height: "auto" }}
                    />
                </div>
                <div className='col-12 col-lg-3 d-block d-md-none' style={{ paddingTop: '2.5%' }}></div>
                <div className="col-12 col-lg-3 text-center">
                    <form className="p-4 border rounded bg-white shadow">
                        <Link to="/">
                            <img
                                src={ablogo}
                                alt="AajuBaaju Logo"
                                className="img-fluid"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Link>
                        <h2 className="mb-6 text-center">Validate Email Address</h2>
                        {email && <><div className="form-group row text-left d-none d-md-block">
                            <label htmlFor="otp" className="col-12 col-md-12 col-form-label">
                                {enableUpdateEmail ? 'Email Id' :
                                    <p className="verify_email_p">Verification Link Sent to <span className={`${styles.spacing}`}>{email}</span></p>}
                                <button type='button'
                                    className={`btn btn-link ${styles.update_mobile}`}
                                    onClick={editEmailId}>{enableUpdateEmail ? 'Cancel' : 'Not your email?'}</button>
                            </label>
                        </div>
                            <div className="form-group row">
                                <div className="col-12 col-md-12">
                                    {enableUpdateEmail &&
                                        <><input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter New Email Id"
                                            value={newEmailId}
                                            name="email"
                                            required onChange={event => setNewEmail(event.target.value)} />
                                            <span className='error'>{error}</span>
                                        </>}
                                </div>
                            </div></>}
                        <div className="form-group row">
                            <div className="col-12 col-md-12">
                                {enableUpdateEmail ? null :
                                    query.includes('expiredtoken') ?
                                        <div>
                                            <p className={`${styles.post_section_p}`}>Your Registration Link got expired.</p>
                                            <p className={`${styles.post_section_p}`}>Please click on Resend Email button below to get new link.</p>
                                        </div> : query.includes('invalidtoken') ?
                                            <div>
                                                <p className={`${styles.post_section_p}`}>Your Verification link is invalid.</p>
                                                <p className={`${styles.post_section_p}`}>Please click on Resend Email button below to get new link.</p>
                                            </div> :
                                            <div>
                                                <p className={`${styles.post_section_p}`}>Please click the validation link sent to your email address.</p>
                                                <p className={`${styles.post_section_p}`}> Please check your spam folder if you did not find in your inbox.</p>
                                                <p className={`${styles.post_section_p}`}>If you did not receive the validation link within 5 minutes please click below.</p>
                                            </div>
                                }
                            </div>
                        </div>
                        <div className="form-group row justify-content-center">
                            <div className="col-12 text-center">
                                <ButtonStyle handleClick={sendingMail ? null : enableUpdateEmail ? updateEmail : resendEmail} className="btn btn-primary w-70">
                                    {sendingMail ? <i className="fas fa-circle-notch fa-spin"></i> : enableUpdateEmail ? 'Update Email' : 'Resend Mail'}
                                </ButtonStyle>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row">
                            <div className="col-12 text-center">
                                <Link to="/contact">Contact Support</Link> &nbsp; | &nbsp; <Link to="/">Back to Home</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}