import React, { useEffect, useState } from 'react';
import { CardsListingData } from './CardsListingData';
import styles from './cover.module.css'

export const CardsListings = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true);
        }, 100);
    }, []);

    return (
                props.listings.map(listing => (
                        <CardsListingData
                            {...props}
                            key={listing.hpId}
                            listing={listing}
                            isViewed={props.viewedContentIds.includes(listing.hpId)}
                        />
                ))
    )
}
