import { store } from "../store/store";

class Loader {
    runSpinner() {
        store.dispatch({ type: 'LOADING', isLoading: true })
    }

    stopSpinner() {
        store.dispatch({ type: 'LOADING', isLoading: false })
    }
}
export default new Loader();