import React, { useState } from 'react';
import { HomeTab } from './HomeTab'

export const HomeTabs = (props) => {

    const [selectedTab, setSelectedTab] = useState('article');

    const handleTabClick = (id) => {
        setSelectedTab(id);
        props.onToggle(id);
    };

    return (
        <>
            <div className='billBoard'>
                <ul style={{ background: '#e5f1e4' }} className="nav listing_type_tabs" role="tablist">
                    <HomeTab onClick={handleTabClick} title={'video_library'} id="article" active={selectedTab === "article"} />
                    <HomeTab onClick={handleTabClick} title={'photo_library'} id="photos" active={selectedTab === "photos"} />
                    <HomeTab onClick={handleTabClick} title={'auto_stories'} id="news" active={selectedTab === "news"} />
                    <HomeTab onClick={handleTabClick} title={'hotel_class'} id="reviews" active={selectedTab === "reviews"} />
                    <HomeTab onClick={handleTabClick} title={'storefront'} id={"business"} active={selectedTab === "business"} />
                    <HomeTab onClick={handleTabClick} title={'celebration'} id={"event"} active={selectedTab === "event"} />
                    <HomeTab onClick={handleTabClick} title={'work'} id={"job"} active={selectedTab === "job"} />
                </ul>
            </div>
            <div className='large_rect'>
                <ul style={{ background: '#e5f1e4' }} className="nav listing_type_tabs_mobile" role="tablist">
                    <HomeTab onClick={handleTabClick} title={'video_library'} id="article" active={selectedTab === "article"} />
                    <HomeTab onClick={handleTabClick} title={'photo_library'} id="photos" active={selectedTab === "photos"} />
                    <HomeTab onClick={handleTabClick} title={'auto_stories'} id="news" active={selectedTab === "news"} />
                    <HomeTab onClick={handleTabClick} title={'hotel_class'} id="reviews" active={selectedTab === "reviews"} />
                    <HomeTab onClick={handleTabClick} title={'storefront'} id={"business"} active={selectedTab === "business"} />
                    <HomeTab onClick={handleTabClick} title={'celebration'} id={"event"} active={selectedTab === "event"} />
                    <HomeTab onClick={handleTabClick} title={'work'} id={"job"} active={selectedTab === "job"} />
                </ul>
            </div>
        </>
    )
}