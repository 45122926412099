import React, { useState } from 'react'
import { AnimatedText } from '../../AnimatedText';
import { FormLabels } from '../../UIElements/Form_label/FormLabels';

const MyPagesInput = (props) => {
    let disableImageElement = false;
    props.state.elements.map(e => {
        if (e.type === 'image' && e.fileURL === null)
            disableImageElement = true;
    })
    const [uploading, setUploading] = useState(true);

    const uploadImage = () => {
        setUploading(true);
    };

    const handleUpload = () => {
        props.state.elements.map((element, index) => {
            props.uploadMedia(index, 'Image');
        })
        uploadImage(true)
    }

    // const newElement = {
    //     type: element.type,
    //     value: '',
    //     file: null,
    //     fileURL: null,
    //     tagLine: '',
    //     id: newElementId,
    //   };

    return (
        <>
            {props.state.elements.map((element, index) => {
                return (
                    <div key={index} id={'article_news_data' + index}>
                        {element.type === 'image' ?
                            <div className="row form-row">
                                <div className="form-group col-md-2 text-right">
                                    <FormLabels text={'Photo'} htmlFor="inputZip" />&nbsp;
                                    <FormLabels text={`${index + 1}*`} htmlFor={`file-upload-${index}`}/>
                                </div>
                                <div className="form-group col-md-4">
                                    <div className="input-group input-upload">
                                        <div className="custom-file">
                                                
                                                <input type="file" className="custom-file-input" required={!props.state.elements[index].fileURL}
                                                    aria-describedby="inputGroupFileAddon01" accept=".png, .jpg, .jpeg, .gif"
                                                    onChange={event => props.selectMedia(event, index)} src={props.state.elements[index].fileURL}
                                                    disabled={props.state.elementStatus[index]} id={`file-upload-${index}`} />
                                                    
                                            <label
                                                className="custom-file-label custom_upload_field image_label article_remove"
                                                htmlFor="inputGroupFile01">{(props.state.elements[index].fileURL) ? <img alt="Uploaded Pic" className="img-fluid thumbnail_image" src={props.state.elements[index].fileURL} /> : 'Choose Photo'}
                                            </label>
                                        </div>

                                    </div></div>
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" placeholder="Photo Tagline" maxLength="250"
                                        value={element.tagLine} onChange={event => props.writeContent(event, index, "image")} />
                                </div>
                                <div className="form-group col-md-2">
                                    {(!props.inputStatus[index]) ?
                                        <div>
                                            <button type='button' className="btn btn-danger" onClick={() => props.removeConfirmation(index, 'Image')}><i className="fa fa-trash-o" aria-hidden="true"></i></button>&nbsp;
                                            <button id={`file-upload-${index}`} className="btn btn-danger" style={{backgroundColor:'#138808', color:'#fff'}} type="button" onClick={() => props.addElements({ index, type: "image" }, index)} disabled={disableImageElement || !uploading || props.elements.length >= 50}><i className="fa fa-arrow-left" aria-hidden="true"></i></button>
                                            {(props.state.elements[index].file) &&
                                                <button type='button' className="btn btn-success media-btn" onClick={handleUpload()}>
                                                    <i className="fa fa-upload" aria-hidden="true"></i>
                                                </button>}
                                        </div> :
                                        <div> <AnimatedText text={'uploading'} /> </div>
                                    }
                                </div>
                            </div> : null}

                    </div>
                )
            })}
            <div className="row form-row newsart_main">
                <div className="form-group col-md-2 text-right">
                    {/* <FormLabels text={'Insert'} /> */}
                </div>
                <div className="form-group col-md-8">
                    <div className="row form-row">
                        <div className="col-md">
                            <button className="btn btn-info btn-block" type="button" onClick={() => props.addElements({ type: "image" })} disabled={disableImageElement || !uploading || props.elements.length >= 50}>Click to Add Photo</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyPagesInput