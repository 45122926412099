import React, { useCallback, useState } from 'react'
import axios from 'axios';
import { container, notifyUser, type } from '../../utility/notifyUser';
import { Timer } from '../../utility/timer';
//import { Link } from 'react-router-dom';
import styles from '../authentication.module.css';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
//import { mobilenumberRegex } from '../../RegxConst';
//import TextDecoration from '../../utility/TextDecoration';
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import authservice from '../service/authservice';
import adService from '../../advertisements/service/adService';
import { useViewport } from '../../utility/useViewport';

export const LoginOTP = (props) => {

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [processing, setProcessing] = useState(false);
    const [sendingOtp, setSendingOtp] = useState(false);
    const [enableOtpResend, setEnableOtpResend] = useState(false);

    const loc = useLocation();
    //console.log(loc)

    const userId = props.match.params.verify;
    const location = useLocation();
    const mobileNumber = location.search.split('=')[1];
    const dispatch = useDispatch()
    const isMobile = useViewport()
    //console.log("login otp")

    const verifyOTP = event => {
        event.preventDefault();
        setProcessing(true);
        setError('')
        axios.post(process.env.REACT_APP_BACKEND_URL + `/abmgr/auth/login-otpvalidation/${userId}?otp=${otp}`)
            .then(response => {
                response.data = loc.userData
                //console.log(response.status === 200)
                if (response.status === 200) {
                    if (response.data === 'Succesfully verified')
                        props.history.push('/')
                    response.data.loginDate = new Date();
                    localStorage.setItem('user', JSON.stringify(response.data));
                    dispatch({ type: 'IS_LOGIN', is_login: true })
                    // const isAdmin = response.data.accountType === 'ADMIN' || response.data.accountType === 'SUPER_ADMIN'
                    // if (isAdmin) {
                    //     dispatch({ type: 'ADMIN_LOGIN', isAdmin: isAdmin })
                    //     props.history.push('/admin-panel/dashboard')
                    // } else {
                    const visitor = {
                        deviceId: authservice.getCookie('hp.rocks.soon'),
                        userId: response.data.id,
                    }
                    axios.post(process.env.REACT_APP_BACKEND_URL + "/abmgr/auth/visitor", visitor)

                    adService.getViewedAds(response.data).then(data => {
                        dispatch({ type: 'ALL_VIEWED_ADS', viewedAds: [...data] })
                        if (props.match.params.required === 'user')
                            props.history.push('/')
                        else
                            props.history.goBack();
                    })
                }

                setProcessing(false);
            }).catch(error => {
                setProcessing(false);
                if (error.response)
                    notifyUser("", error.response.data, type.DANGER, container.BOTTOM_RIGHT)
            })
    }

    const resendOTP = () => {
        setSendingOtp(true)
        setEnableOtpResend(false)
        axios.get(process.env.REACT_APP_BACKEND_URL + '/abmgr/auth/resendotp/' + userId).then(response => {
            setSendingOtp(false)
            if (response.status == 200)
                notifyUser("OTP Sent!", "OTP is sent to your registered mobile number", type.SUCCESS, container.BOTTOM_RIGHT)
        }).catch(err => {
            setSendingOtp(false)
            setEnableOtpResend(true)
            if (err.response)
                notifyUser("OTP Error", "Something went wrong while sending OTP", type.DANGER, container.BOTTOM_RIGHT)
        })

    }

    const enableResend = () => {
        setEnableOtpResend(true)
    }

    return (
        <div className={isMobile ? `${styles.RegistrationMob} low_padding` : `${styles.Registration} low_padding`}>
            <h2 className={`${styles.otp_header} ${styles.registration_header}`}>Validate Mobile Number</h2>
            <div className={`${styles.otp_form}`}>
                <form onSubmit={processing ? null : verifyOTP}>
                    <div className="form-row otp_section">
                        <div className="form-group col-md-12">
                            <label htmlFor="otp">
                                <>OTP Sent to <span className={`${styles.spacing}`}>{mobileNumber}</span></>
                            </label>
                        </div>
                        <div className="form-group col-md-12">
                            <label htmlFor="otp">Enter OTP</label>
                            <input type="text" className="form-control" placeholder="Enter OTP" value={otp} name="otp"
                                required onChange={event => setOtp(event.target.value)} />
                            <span className='error'>{error}</span>
                        </div>
                        <div className="col-md-12 text-center">
                            <ButtonStyle type="submit" className={`btn ${styles.submit_btn}`}>
                                {processing ? <i className="fas fa-circle-notch fa-spin"></i> : 'Submit'}
                            </ButtonStyle>
                        </div>
                    </div>

                </form>
                <div className={`row ${styles.resend_otp}`}>
                    <div className="col-md-12 text-center">
                        {sendingOtp ? <i className="fas fa-circle-notch fa-spin"></i> :
                            <><button disabled={enableOtpResend ? false : true} type='button' className={`btn btn-link ${styles.resend_otp_btn}`} onClick={resendOTP}>Resend OTP</button>
                                {enableOtpResend ? null : <Timer initialMinute={2} initialSeconds={0} onTimeUp={enableResend} />}</>}
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
}