import React from 'react'
import styles from './buttons.module.css'



export const ButtonStyle = (props) => {
    return (
      <button disabled={props.disabled} id={props.id} onClick={props.handleClick} type={props.type} className={`${styles.btn_warning} ${props.className ? props.className : ''}`}
      style={props.style ? props.style : null}>
        {props.text ? props.text : props.children}
      </button>
    );
   }