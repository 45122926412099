// eslint-disable-next-line no-useless-escape
export const validEmailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i);
export const strongPasswordRegex = RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/i);
export const usernameRegex = RegExp(/^[A-Za-z0-9_\.]{3,16}$/);
//export const mobilenumberRegex = RegExp(/^[0][1-9]\d{9}$|^[6-9]\d{9}$/i);
export const mobilenumberRegex = RegExp(/^([6-9]\d{0,})$/i); // check only first digit to between 6 to 9
export const phonenumberRegex = RegExp(/^\+?(?![^ -]*[ -]{2})(?=(?:[ -]*\d){10,12}$)\d[\d -]*\d$/); // for both mobile and landline numbers
// eslint-disable-next-line no-useless-escape
export const webSite = RegExp(/^((?:http(s)?:\/\/[w]{3}\.))?(?:[w]{3}\.)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/);
export const webSiteDomain = RegExp(/^(https?:\/\/)?((www\.)?[\w.-]+)(:\d+)?(\/[\w\-.~:/?#[\]@!\$&'\(\)\*\+,;=%]*)?$/);
// export const webSite = RegExp(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/)
//export const personName = RegExp(/^.*[a-zA-Z][^a-zA-Z]*$/) || /^\s*[a-zA-Z]/
export const personName = RegExp(/\w*[a-zA-Z]\w*/)
export const names = RegExp(/\s*\S+.*[a-zA-Z]{2}\w*/)
export const searchFilterNames = RegExp(/^[a-zA-Z0-9\s!@#$%^&*()-_+=<>?/,.:;'"`~{}\[\]]*$/)
//export const address = RegExp(/^\s*\S+(?:\s+\S+){2}/);
export const address = RegExp(/^\s*\S+.*[a-zA-Z]{2}/);
export const zipCode = RegExp(/\s[0-9]{6}$/);
export const pan = RegExp(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/);
export const gstNumber = RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
export const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
export const ifscRegex = RegExp(/^[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/);