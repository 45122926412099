import React, { useState } from 'react'
import axios from 'axios'
import { container, notifyUser, type } from '../../utility/notifyUser';
import styles from '../authentication.module.css';
import { useViewport } from '../../utility/useViewport';
import fullEmployment from '../../../assets/fullEmployment.jpg'
import ablogo from '../../../assets/ablogo.svg'
import { ButtonStyle } from '../../UIElements/Buttons/ButtonStyle';
import { Link } from 'react-router-dom/cjs/react-router-dom';

export const ForgotPassword = () => {

    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [processing, setProcessing] = useState(false);
    const isMobile = useViewport();


    const handleSubmit = (event) => {
        event.preventDefault();
        setProcessing(true);
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/abmgr/auth/forgotpassword?username=${username}&email=${email}`)
            .then(response => {
                setProcessing(false);
                setUsername('')
                setEmail('')
                if (response.data)
                    notifyUser('', response.data, type.SUCCESS, container.BOTTOM_RIGHT);
            }).catch(error => {
                setProcessing(false);
                if (error.response && error.response.data)
                    notifyUser('', error.response.data, type.DANGER, container.BOTTOM_RIGHT)
                else
                    notifyUser('', 'Something is not right', type.WARNING, container.BOTTOM_RIGHT)
            })
    }
    return (
        <div className="container-fluid" style={{ padding: '2%' }}>
            <div className="row">
                <div className="col-12 col-lg-9 text-left">
                    <img
                        src={fullEmployment}
                        alt="Advertisement Space"
                        className="img-fluid"
                        style={{ width: '100%', height: "auto" }}
                    />
                </div>
                <div className='col-12 col-lg-3 d-block d-md-none' style={{ paddingTop: '2.5%' }}></div>
                <div className="col-12 col-lg-3 text-center">
                    <form onSubmit={handleSubmit} className="p-4 border rounded bg-white shadow">
                        <Link to="/">
                            <img
                                src={ablogo}
                                alt="AajuBaaju Logo"
                                className="img-fluid"
                                style={{ width: "100%", height: "auto" }}
                            />
                        </Link>
                        <h2 className={`${styles.registration_header}`}>Retrieve Password Form</h2>

                        <div className="form-group row text-left d-none d-md-block">
                            <label htmlFor="username" className="col-12 col-md-3 col-form-label">
                                Username
                            </label>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                    name="username"
                                    required
                                    onChange={event => setUsername(event.target.value)}
                                    noValidate
                                />
                            </div>
                        </div>

                        <div className="form-group row text-left d-none d-md-block">
                            <label htmlFor="email" className="col-12 col-md-3 col-form-label">
                                Email
                            </label>
                        </div>
                        <div className="form-group row">
                            <div className="col-12 col-md-12">
                                <input
                                    type='email'
                                    className="form-control"
                                    placeholder="Email"
                                    name="email"
                                    required
                                    onChange={event => setEmail(event.target.value)}
                                    noValidate
                                />
                            </div>
                        </div>
                        <div className="form-group row justify-content-center" style={{ padding: '8%' }}>
                            <div className="col-12 text-center">
                                <ButtonStyle type="submit" className="btn btn-primary w-50">
                                    {processing ? <i className="fas fa-circle-notch fa-spin"></i> : "Submit"}
                                </ButtonStyle>
                            </div>
                        </div>
                        <hr></hr>
                        <div className="form-group row">
                            <div className="col-12 text-center">
                                <Link to="/contact">Contact Support</Link> &nbsp; | &nbsp; <Link to="/">Back to Home</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}