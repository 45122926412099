import { store } from '../../store/store';

class AuthService {
  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  getCurrentUserName() {
    try {
      return this.getCurrentUser().username;
    } catch (error) {
      return null;
    }
  }
  getCurrentUserid() {
    try {
      return this.getCurrentUser().id;
    } catch (error) {
      return null;
    }
  }

  getEmail() {
    try {
      return this.getCurrentUser().email;
    } catch (error) {
      return null;
    }
  }

  getApprovalStatus() {
    try {
      return this.getCurrentUser().directApproval;
    } catch (error) {
      return false;
    }
  }

  getAccountType() {
    try {
      return this.getCurrentUser().accountType;
    } catch (error) {
      return null;
    }
  }

  getSubscriptionType() {
    try {
      return this.getCurrentUser().subscriptionType;
    } catch (error) {
      return null;
    }
  }

  logOut() {
    store.dispatch({ type: 'ADMIN_LOGIN', isAdmin: false })
    store.dispatch({ type: 'IS_LOGIN', is_login: false })
    localStorage.removeItem('user')
  }

  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ')
        c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0)
        return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  setCookie(name, value) {
    var expires = "";
    var date = new Date();
    var midnight = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    expires = "; expires=" + midnight.toUTCString();
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
    return value;
  }
}

export default new AuthService();