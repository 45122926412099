import React, { useEffect } from 'react';
import BillBoard from '../advertisements/BillBoard';
import FooterStyle from './FooterStyle.module.css'
import styles from '../listings/forms/addpost.module.css'
import LargeRectangle from '../advertisements/LargeRectangle'

export const RefundPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <div className={`${FooterStyle.grid_container} terms_of_service`}>
                <div className="row">
                    <section className="col-12">
                        <h1 className={`${FooterStyle.pageHeading} text-center pb-5`}>REFUNDS / CANCELLATION:</h1>
                        <ul>
                            <li>
                                <p className={`${FooterStyle.paragraph}`}>The subscribers can avail a refund of the 1st subscription paid, by sending a message through the CONTACT SUPPORT link, if they are not satisfied with the services of aajubaaju.in within 15 days from the date of registration. The remaining amount after deducting the earnings earned till the date of request for refund sent will be processed and transferred to the registered bank account.</p>
                                <p className={`${FooterStyle.paragraph}`}>Note:<br></br>GST is not refundable.<br></br>Refund is not applicable on the Renewed Subscriptions.</p>
                                <p className={`${FooterStyle.paragraph}`}>The amounts paid to avail Paid Services are nonrefundable.</p>
                                <p className={`${FooterStyle.paragraph}`}>If you choose to delete or deactivate any Paid Services, we will not be liable to refund you.</p>
                                <p className={`${FooterStyle.paragraph}`}>In the event you are not able to avail of the Paid Services on the Platform on account of any technical reasons occurred on Our side, on receipt of payment confirmation, the amount eligible for refund on a prorate basis shall be credited to your account. For example, if you buy a paid service which has one year validity gets disrupted after 6 months on account of any technical reasons occurred on Our side, the amount remaining for the 6 months will be refunded.</p>
                                <p className={`${FooterStyle.paragraph}`}>The above refund policy shall not be applicable in the event any of your content/advertisement in Paid Services is found to be violating the Terms or there has been any abuse reported in relation to your content/advertisement or your content/advertisement is deleted or not re-posted on the Platform on account of any technical reasons due to any subsequent editing done by you.</p>
                                <p className={`${FooterStyle.paragraph}`}>Refund is not applicable on Monthly Subscriptions.</p>
                            </li>
                        </ul>
                    </section>
                </div>
            </div>
            <div className="listings_footer">
                <div className={`${styles.large_rect} row`}>
                    <LargeRectangle spot={"-1"} />
                </div>
                <div className={`${styles.billBoard} row`} style={{backgroundColor:'#e5e5f2',paddingTop: '100px', paddingBottom: '100px'}}>
                    <BillBoard spot={"-1"} />
                </div>
            </div>
        </>
    )
}

